// src/components/Footer/FooterSection.js
import React from 'react';
import { Footer } from './StyledFooter';
import MailNews from '../Newslaters/MailNews';
import FooterLink from './FooterLink';

const FooterSection = () => {
  return (
    <Footer>
      <MailNews />
      <div className='footer'>
        <p>Notre mission.</p>
        <p>Notre devoir est de pouvoir proposer des compléments alimentaires de qualités, accessible à tous.</p>
      </div>
      <div className='footer'>
        <FooterLink label="SHILAJIT" path="/shilajit" />
        <FooterLink label="SUIVRE MA COMMANDE" path="/suivit-de-ma-commande" />
        <FooterLink label="PageCGUCGV" path="/PageCGUCGV" />
        <FooterLink label="PageContact" path="/PageContact" />
      </div>
      <div className='footer'>
        <FooterLink label="LIENS UTILS" path="/liens-utils" />
        <FooterLink label="POLITIQUE DE CONFIDENTIALITÉ" path="/politique-confidentialite" />
      </div>
    </Footer>
  );
};

export default FooterSection;
