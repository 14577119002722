import React from 'react';
import styled from 'styled-components'

const BottomGallery = styled.div `
.thumbnail {
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 5px;
    overflow: hidden;
    transition: border-color 0.3s;
  }
  .thumbnail.selected {
    border-color: #007bff;
  }
  .thumbnail-image {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 5px;
  }
`

const ImageGalleryItem = ({ image, isSelected, onClick }) => {

  return (
    <BottomGallery
      className={`thumbnail ${isSelected ? 'selected' : ''}`}
      onClick={onClick}
    >
      <img src={image} alt="Thumbnail" className="thumbnail-image" />
    </BottomGallery>
  );
};

export default ImageGalleryItem;
