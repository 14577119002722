import axios from 'axios';

const sendFormDataToServer = async (formData) => {
  try {
    const response = await axios.post('http://localhost:4000/form-data', formData);
    console.log('Form data sent successfully:', response.data);
  } catch (error) {
    console.error('Error sending form data:', error);
  }
};

export default sendFormDataToServer;
