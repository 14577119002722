// src/components/FinalCommande.js
import React from 'react';
import useButtonCommande from '../../hooks/ButtonCommande';
import useCartActions from '../../hooks/PanierGestion';
import { FinalCommandeContainer } from './StyledCartModal';
import GlowingButton from '../styled/GlowingButton'


const FinalCommande = ({ onClose }) => {
  const { cart } = useCartActions();
  const { buttonCommande } = useButtonCommande();

  return (
    <FinalCommandeContainer>
      <div className='price-commande-total'>
      <p>Taxes incluses et frais d'expédition calculés lors du paiement</p>

        <div className='total-commande'>
          <p className='resultat'>total</p>
          <p className='resultat'>{cart.total.toFixed(2)}€</p>
        </div>
      </div>
      <div className="button-container">
      <GlowingButton onClick={buttonCommande}>Passer la Commande</GlowingButton>
      </div>
    </FinalCommandeContainer>
  );
};

export default FinalCommande;
