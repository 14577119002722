// src/hooks/PanierGestion.js
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, updateQuantity, removeFromCart, clearCart } from '../redux/action/CartActions';

const useCartActions = () => {
  const dispatch = useDispatch();
  const cart = useSelector(state => state.cart);

  const incrementQuantity = (id) => {
    const item = cart.items.find(item => item.id === id);
    const newQuantity = item.quantity + 1;
    dispatch(updateQuantity(id, newQuantity));
  };

  const decrementQuantity = (id) => {
    const item = cart.items.find(item => item.id === id);
    const newQuantity = item.quantity > 1 ? item.quantity - 1 : 1;
    dispatch(updateQuantity(id, newQuantity));
  };

  const handleQuantityChange = (id, e) => {
    const newQuantity = Math.max(1, Math.round(Number(e.target.value)));
    dispatch(updateQuantity(id, newQuantity));
  };

  const handleRemoveItem = (id) => {
    dispatch(removeFromCart(id));
  };

  const clearCartItems = () => {
    dispatch(clearCart());
  };

  return {
    cart,
    incrementQuantity,
    decrementQuantity,
    handleQuantityChange,
    handleRemoveItem,
    clearCartItems,
    addToCart: (item) => dispatch(addToCart(item)), // Assurez-vous que cette fonction est correcte
  };
};

export default useCartActions;
