import React from 'react'

const PageSuivitCommande = () => {
  return (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
        <p>Cette page est en cours de developpement , merci pour votre comprehenssion </p>
    </div>
  )
}

export default PageSuivitCommande