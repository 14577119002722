import React from 'react';
import { MarqueeOffre } from "./StyledMarquee";

const Marquee = () => {
  const text = "LIVRAISON OFFERTE SUR TOUT LE SITE ";
  const text2 = "2 POTS ACHETÉS, LE 3ÈME OFFERT";
  const redDots = <span style={{ color: 'black' }}>............</span>;
  const blackDot = <span>•</span>;

  const combinedText = (
    <>
      {text}
      {redDots}
      {blackDot}
      {redDots}
      {" "}
      {text2}
      {redDots}
      {blackDot}
      {redDots}
    </>
  );

  const repeatedText = Array(25).fill(combinedText).map((part, index) => (
    <React.Fragment key={index}>{part}</React.Fragment>
  ));

  return (
    <MarqueeOffre>
      <p>{repeatedText}</p>
    </MarqueeOffre>
  );
};

export default Marquee;
