import React from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import axios from 'axios';
import config from '../server/config';
import { FormContact } from './StyledFormContact';
import ApplePayButton from './ApplePayButton';

const CheckoutForm = ({ handleSubmit }) => {
  const stripe = useStripe();
  const elements = useElements();
  const items = useSelector(state => state.cart.items);
  const totalAmount = useSelector(state => state.cart.total);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const isFormValid = await handleSubmit(event);

    if (!isFormValid) {
      return;
    }

    if (!stripe || !elements) {
      console.error("Stripe or elements not loaded");
      return;
    }

    try {
      console.log('Submitting to:', `${config[process.env.NODE_ENV].API_URL}/create-checkout-session`);
      console.log('Items:', items);
      console.log('Total Amount:', totalAmount);

      const response = await axios.post(`${config[process.env.NODE_ENV].API_URL}/create-checkout-session`, {
        items,
        totalAmount,
      });

      console.log('API Response:', response.data);
      const session = response.data;

      if (!session.id) {
        console.error("Session ID is missing in the response");
        return;
      }

      const { error } = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (error) {
        console.error('Error redirecting to checkout:', error);
      }
    } catch (error) {
      console.error('Error creating Stripe checkout session:', error);
    }
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <FormContact>
        <ApplePayButton />
        <button type="submit">Procéder au paiement {totalAmount.toFixed(2)} €</button>
      </FormContact>
    </form>
  );
};

export default CheckoutForm;
