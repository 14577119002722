import styled from 'styled-components';

export const PayementSuccess = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  div {
    text-align: center;
    background: #E8F5E9;
    border-radius: 20px;
    padding: 20px;
    .icons-valider {
      color: green;
      height: 10vh;
      width: 10vw;
    }
    p {
      margin-bottom: 20px;
      font-size: 20px;
    }
  }
  @media screen and (max-width: 500px) {
    div {
      width: 80vw;
      p {
        font-size: 16px;
      }
      .A {
        font-size: 20px;
        color: #2E7D32;
        font-weight: 800;
      }
    }
  }
`;


export const PayementCancel = styled.section`
display: flex;
align-items: center;
justify-content: center;
height: 100vh;
div {
    text-align: center;
    background: #E8F5E9;
    border-radius: 20px;
    padding: 20px;
    .icons-cancel {
        color: green;
        height: 10vh;
        width: 10vw;
    }
    p {
        margin-bottom: 20px;
        font-size: 20px;
    }
    .B {
        font-size: 20px;
        color: red;
        font-weight: 800;
    }
}
`
