import styled from 'styled-components'

export const Footer = styled.section`
height: auto;
width: 100vw;
flex-direction: column;
display: flex;
justify-content: space-around;
background: rgb(0, 0, 0);
.footer {
    color: white;
    margin: 10px;
    
    p {
        margin: 20px;
        width: 50vw;
          font-family: 'Trebuchet MS', sans-serif;


    }
}
@media (max-width: 321px) {

.footer {
    margin: 10px;
    p {
        margin: 10px;
        font-size: 12px;
    }
}
}
@media (max-width: 500px) {

    .footer {
        p {
            margin: 15px;
            font-size: 13px;
        }
    }
    }
`