// src/components/ModalBackdrop.js
import styled from 'styled-components';

const BackdropContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = ({ onClick, children }) => {
  return <BackdropContainer onClick={onClick}>{children}</BackdropContainer>;
};

export default Backdrop;
