import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import RotatingImage from './RotatingImage';
import LogotPuramina from '../../assets/image/LogotPuramina.png';

const slideUp = keyframes`
  0% {
    height: 100vh;
    top: 0;
  }

  60% {
    height: 20vh;
    top: 0;
  }
  70% {
    height: 30vh;
    top: 0;
  }

  100% {
    height: 0;
    top: 0;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const AnnouncementWrapper = styled.div`
  display: ${({ $visible }) => ($visible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  width: 100vw;
  background-color: black;
  color: white;
  position: fixed;
  left: 0;
  z-index: 100010;
  animation: ${slideUp} 1s ease-in-out forwards;
  bottom: 0;
  border-bottom: 3px solid black;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
`;

const AnnouncementText = styled.div`
  font-size: 24px;
  color: black;
  font-weight: 800;
  padding: 20px;
  border-radius: 10px;
  animation: ${fadeOut} 2.5s ease-in-out forwards;

  .logotLoading {
    width: 10vw;
    margin: 30px;
    height: 100%;
  }
  @media (max-width: 1000px) {
    .logotLoading {
        width: 18vw;
        margin: 30px;
        height: 100%;
      }
  }
`;

const Announcement = ({ show }) => {
  const [visible, setVisible] = useState(show);

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setVisible(false);
      }, 1000); // Annonce visible pendant 1 seconde
    }
  }, [show]);

  return (
    <AnnouncementWrapper $visible={visible}>
      <AnnouncementText>
        <RotatingImage 
          src={LogotPuramina} 
          alt="logot" 
          width="20vw" 
          height="100%" 
          rotationDuration="1s"  // Durée d'animation plus courte pour une rotation plus rapide
          className="logotLoading" 
        />
      </AnnouncementText>
    </AnnouncementWrapper>
  );
};

export default Announcement;
