import styled from 'styled-components';

export const FormContact = styled.div`
  button {
    background: linear-gradient(135deg, #4CAF50, #43A047);
    color: white;
    padding: 13px 20px;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
`;
