import styled, { keyframes } from 'styled-components';

const shine = keyframes`
0% {
    left: -100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
`;

const GlowingButton = styled.button`
position: relative;
  padding: 20px 40px;
  font-size: 1em;
  color: #fff;
  background-color: #000;
  border: none;
  border-radius: 50px;
  overflow: hidden;
  cursor: pointer;
  width: 40vw;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 200%;
    height: 100%;
    background: linear-gradient(30deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0) 100%);
    transition: 1s;
    animation: ${shine} 4s infinite;
  }
  @media (max-width: 1050px) {
    width: 45vw;

  }
  @media (max-width: 700px) {
    width: 65vw;

  }
  @media (max-width: 500px) {
    width: 100vw;
  }
`;

export default GlowingButton;
