import React, { useState } from 'react';
import FaqItem from './FaqItem';
import { FAQQuestionReponse } from './StyledFAQ';

const faqs = [
  { 
    question: 'Pourquoi notre Shilajit est-il plus coûteux au gramme que celui de nos concurrents ?', 
    answer: 'Notre Shilajit Puramina est 100 % pur et authentique. Nous travaillons directement avec notre fournisseur, qui nous fournit des vidéos de contrôle qualité régulières pour garantir l\'authenticité. Contrairement à des produits moins chers qui peuvent être altérés ou dilués, notre Shilajit maintient une efficacité optimale et est totalement sûr pour votre santé. Investir dans Puramina, c\'est choisir la qualité et la transparence.' 
  },
  { 
    question: 'Combien de portions représentent 10 grammes de Shilajit Puramina ?', 
    answer: '10 grammes de notre Shilajit Puramina contiennent environ 30 portions de 333 mg chacune. Nous conseillons une portion par jour, vous offrant un mois complet de bienfaits. Une dose de 500 mg correspond à un petit pois.' 
  },
  { 
    question: 'En quoi le Shilajit est-il bénéfique pour la musculation ?', 
    answer: 'Le Shilajit, riche en minéraux et acides fulviques, soutient la musculation en augmentant la masse musculaire et en accélérant la récupération post-entraînement. Il booste également les niveaux d\'énergie, améliorant ainsi vos performances et votre endurance.' 
  },
  { 
    question: 'Le Shilajit booste-t-il la testostérone ?', 
    answer: 'Oui, le Shilajit augmente les niveaux de testostérone chez les hommes, améliorant la récupération musculaire, la force et la libido.' 
  },
  { 
    question: 'Quelle est la réaction du corps à la prise de Shilajit ?', 
    answer: 'Le Shilajit Puramina est bien toléré grâce à sa composition naturelle. Toutefois, certains peuvent ressentir de légers maux de tête ou une sensibilité digestive au début. Pour éviter cela, commencez par une petite dose pour permettre à votre corps de s\'adapter. Consultez un professionnel de santé si les symptômes persistent.' 
  },
  { 
    question: 'Quel est le délai de livraison ?', 
    answer: 'Le délai de livraison dépend de votre localisation. Nous expédions toutes les commandes sous 24 heures. La livraison standard prend généralement 3 à 7 jours ouvrables.' 
  },
  { 
    question: 'Comment prendre le Shilajit Puramina pour des bénéfices optimaux ?', 
    answer: 'Prenez une petite quantité de Shilajit Puramina, équivalente à un grain de riz, pour des bienfaits maximaux. Cela permet à un pot de 10 grammes de durer aussi longtemps qu\'un pot de 30 grammes de produits moins purs. Consommez avec un repas pour une meilleure absorption.' 
  },
  { 
    question: 'Quand voir les résultats avec le Shilajit ?', 
    answer: 'Les résultats du Shilajit varient, mais beaucoup remarquent une amélioration de l\'énergie, de l\'endurance, de la récupération, et une diminution du stress et de l\'anxiété dans les premières semaines. Les meilleurs résultats se voient après plusieurs semaines d\'utilisation continue.' 
  },
  { 
    question: 'Les femmes peuvent-elles prendre le Shilajit PURAMINA ?', 
    answer: 'Oui, le Shilajit PURAMINA convient aux hommes et aux femmes. Sa richesse en minéraux et oligo-éléments soutient l\'équilibre hormonal, la vitalité et l\'endurance. Consultez un professionnel de santé avant de commencer, surtout en cas de grossesse ou d\'allaitement.' 
  },
];


const QuestionReponse = () => {
  const [openFaqIndex, setOpenFaqIndex] = useState(null);

  const toggleFaq = index => {
    setOpenFaqIndex(openFaqIndex === index ? null : index);
  };

  return (
    <FAQQuestionReponse>
      <h4>FAQ</h4>
      <p>Vous avez des questions ? Nous avons les réponses.</p>
      {faqs.map((faq, index) => (
        <FaqItem
          key={index}
          faq={faq}
          isExpanded={index === openFaqIndex}
          onToggle={() => toggleFaq(index)}
        />
      ))}
      <p className="horaire-reponse">
      Notre service clientèle est disponible 24h/7. Temps de réponse moyen de 8h à 00h00 : 1/3 h.
      </p>
    </FAQQuestionReponse>
  );
};

export default QuestionReponse;
