// src/components/ModalContainer.js
import React from 'react';
import styled from 'styled-components'

const ModalContainerStyled = styled.div`
  color: black;
  background: #f4f4f4;

  
`;

const ModalContainer = ({ children }) => {
  return <ModalContainerStyled>{children}</ModalContainerStyled>;
};

export default ModalContainer;
