import React, { useState } from 'react';
import ImageGalleryItem from './ImageGalleryItem';
import styled from 'styled-components'

const ImagesGallery = styled.div `
display: flex;
flex-direction: column;
align-items: center;
.selected-image-container {
  width: 100%;
  max-width: 600px;
  margin-bottom: 20px;
}
.selected-image {
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}
.thumbnail-container {
  display: flex;
  justify-content: center;
  gap: 10px;
}
`

const ImageGallery = ({ images }) => {
  const [selectedImage, setSelectedImage] = useState(images[0]);


  return (
    <ImagesGallery>
      <div className="selected-image-container">
        <img src={selectedImage} alt="Selected" className="selected-image" />
      </div>
      <div className="thumbnail-container">
        {images.map((image, index) => (
          <ImageGalleryItem
            key={index}
            image={image}
            isSelected={image === selectedImage}
            onClick={() => setSelectedImage(image)}
          />
        ))}
      </div>
    </ImagesGallery>
  );
};

export default ImageGallery;
