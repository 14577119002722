// src/components/Footer/FooterLink.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const FooterLink = ({ label, path }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(path);
  };

  return (
    <p onClick={handleClick} style={{ cursor: 'pointer' }}>
      {label}
    </p>
  );
};

FooterLink.propTypes = {
  label: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

export default FooterLink;
