import React from 'react'
import {PayementCancel} from './StyledPayementPage.js'
import { FcCancel } from "react-icons/fc";
const Cancel = () => {
  return (
    <PayementCancel>
      <div>
        <span><FcCancel className='icons-cancel '/></span>
        <p className='B'>Paiement Invalidé</p>
        <p>Pour toute question, veuillez contacter notre service client à <a href="mailto:puramina.shilajit@gmail.com">support@puramina.com</a></p>
      </div>
    </PayementCancel>
  )
}

export default Cancel