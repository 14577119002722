// src/config.js
const config = {
    development: {
      API_URL: 'http://localhost:4000',
    },
    production: {
      API_URL: 'https://puramina.fr',
    },
  };
  
  export default config;
  