// src/components/modal/StyledCartModal.js
import styled from 'styled-components';

export const ModalComble = styled.div`
  border-radius: 10px 10px 0 0;
  width: 100vw;
  height: 90vh;
  background: #f4f4f4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10001;
  position: fixed;
  bottom: 0; /* Mettre la modal en bas de l'écran */
  left: 50%; /* Centrer horizontalement */
  transform: translateX(-50%); /* Ajuster la position */
  cursor: auto;
  padding: 20px;

  .commande-visible-off {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50vw;
    padding: 10px;
    border: 2px solid #e0e0e0;
    border-radius: 10px;
    p {
      margin: 5px 0;
    }
  }

  .notification {
    background-color: #4caf50;
    color: white;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 10px;
    transition: opacity 0.5s ease-in-out;
  }

  .selected-pack {
    background: rgb(232, 232, 232);
    width: 40%;
    height: auto;
    margin: 10px auto;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative; /* Important pour .button-title */
    
  }

  .button-title2 {
    position: absolute;
    display: flex;
      align-items: center;
      justify-content: center;
    bottom: 0;
    right: 0;
    font-size: 15px;
    font-weight: 800;
    padding: 5px 15px;
    width: 25%;
    height: 2vh;
    border-top: 4px solid #f4f4f4;
    border-left: 4px solid #f4f4f4;
    border-radius: 10px 0 0 0px;
    background: rgb(232, 232, 232);
    color: black;
  }

  .selected-pack input {
    justify-content: center;
    align-items: center;
    text-align: center;
    background: inherit;
    width: 4vw;
    height: 3vh;
    border: 0.5px solid black;
    border-radius: 10%;
    margin: 0 10px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .quantity-pots {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
    margin: 10px 0 10px;
  }

  .quantity-pots button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: black;
    color: white;
    font-size: 20px;
    width: 7vw;
    height: 4vh;
    border: 1px solid black;
    border-radius: 10px;
  }

  .button-close {
    justify-content: center;
    display: flex;
    align-items: center;
    font-size: 30px;
  }

  .button-close-sup {
    position: absolute;
    top: 4%;
    right: 7%;
    border: none;
    border-radius: 50%;
  }

  @media screen and (max-width: 1300px) {
    .commande-visible-off {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 70vw;
      padding: 10px;
      border: 2px solid #e0e0e0;
      border-radius: 10px;
      p {
        margin: 5px 0;
      }
    }
  }

  @media screen and (max-width: 850px) {
    .commande-visible-off {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 85vw;
      padding: 10px;
      border: 2px solid #e0e0e0;
      border-radius: 10px;
      margin: 0px;
      p {
        margin: 5px 0;
      }
    }
    .selected-pack {
      background: rgb(232, 232, 232);
      width: 70%;
      height: auto;
      margin: 10px auto;
      border-radius: 20px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .selected-pack input {
      justify-content: center;
      align-items: center;
      text-align: center;
      background: inherit;
      width: 5vw;
      height: 3vh;
      border-radius: 15%;
      margin: 0 10px;
    }

    .quantity-pots button {
      width: 10vw;
      height: 4vh;
      margin: 0 10px 0 10px;
    }
  }

  @media screen and (max-width: 500px) {
    height: 80vh;

    .title-modal {
      position: absolute;
      top: 10%;
      font-size: 22px;
      font-weight: 800;
      height: 10vh;
      span {
        background: #a5d6a7;
        border-radius: 15px;
        padding: 5px;
        color: #388e3c;
        border: 1.5px solid black;
      }
    }
    .button-title2 {

      width: 25%;
      font-size: 10px;

    }

    .commande-visible-off {
      text-align: center;
      width: 90vw;
      padding: 10px;
      border: 2px solid #e0e0e0;
      border-radius: 10px;
      background: #eeeeee;
      p {
        margin: 5px 0;
        font-size: 17px;
        font-weight: 800;
      }
    }
    .selected-pack {
      margin: 3px;
      width: 85%;
    }

    .selected-pack input {
      width: 6vw;
      height: 3vh;
      border: 1px solid gray;
      border-radius: 5px;
      margin-right: 5px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .quantity-pots button {
      background-color: black;
      width: 18vw;
      height: 3.5vh;
      font-size: 16px;
      border: 1px solid black;
      border-radius: 100px;
      margin: 0px;
    }
  }

  @media screen and (max-height: 800px) {
    .selected-pack {
      margin: 3px;
      padding: 10px;
      width: 90%;
      border-top: 1px solid #A5D6A7;
      background: white;
    }

    .selected-pack input {
      width: 6vw;
      height: 3vh;
      border: 1px solid gray;
      border-radius: 5px;
      margin-right: 5px;
    }
  }
`;


export const FinalCommande = styled.div`
  .price-commande {
    border: 1px solid black;
    border-radius: 10px;
    height: 40vh;
    width: 60vw;
    position: relative;
    margin-top: 40px;

    .price-commande-total {
      flex-direction: column;
      display: flex;
    }
  }
`;

export const FinalCommandeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  border: 1px solid gray;
  border-radius: 20px;
  width: 50%;
  height: auto;
  padding: 20px;
  position: absolute;
  bottom: 4%;
  background: #E0E0E0;
  

  .icon-nav {
    font-size: 30px;
  }

  .price-commande-total {
    text-align: center;

    p {
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      padding: 3px 0;
        font-family: 'Trebuchet MS', sans-serif;

    }

    .total-commande {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 10px 0;

      .resultat {
        font-size: 1.5em;
        font-weight: bold;
      }
    }
  }

  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;

    button {
      border: none;
      cursor: pointer;
      font-size: 1em;
      background-color: black;
      color: white;
      height: 8vh;
      border-radius: 50px;
    }

    .button-commande {
      margin: 0px 10px 0 0;
      width: 40vw;
      border-right: 1px solid white;
    }

    .button-panier {
      min-width: 10vw;
    }
  }

  @media screen and (max-width: 850px) {
    border: 1px solid #e0e0e0;
    position: absolute;
    bottom: 0;
    margin: 15px 0 0px;
    width: 100%;
    height: auto;
    background: #f5f5f5;

    .button-container {
      width: 75%;

      button {
      }

      .button-commande {
        width: 100%;
      }
    
  }

  @media screen and (max-width: 500px) {
    border: 1px solid #e0e0e0;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: auto;
    background: #f5f5f5;
    padding: 20px;
    padding: 5px;

    .price-commande-total {
      text-align: center;
      padding: 5px;

      span {
        font-size: 1rem;
        font-weight: bold;
      }
    }
    .total-commande {
      font-weight: 800;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 13px;
    }

    .button-container {
      width: 80%;
      font-size: 20px;

      button {
        border: none;
        cursor: pointer;
        font-size: 0.7em;
        height: 8vh;
        border-radius: 50px;
      }

      .button-commande {
        margin: 0px;
        width: 100%;
      }

      .button-panier {
        min-width: 10vw;
      }
    }
  }

  @media screen and (max-height: 750px) {
    margin: 0px;
    border-top: 1px solid green;
    border-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .button-container .button-commande {
      width: 80%;
    }

    .price-commande-total {
      padding-top: 0px;

      p {
        padding: 0px 20px 0 20px;

        font-size: 15px;
        margin: 0px;
      }

      .total-commande {
        font-weight: 800;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .resultat {
          font-size: 16px;
          margin: 0px;
        }
      }
    }
  }
`;
