import React from 'react';
import Marquee from '../Marquee/Marquee';
import NavBar from '../nav/NavBar';
import { PuraminaPage, CenterPuraminaPage } from './StyledPuraminaPage';
import Fidelity from '../FIdelity/Fidelity';

const PuraminaInfos = () => {
  return (
    <PuraminaPage>
      <div>
        <Marquee />
        <NavBar />
      </div>
      <CenterPuraminaPage>
        <div>
        <h1>Shilajit Puramina - La Pureté des Montagnes de l'Himalaya</h1>
        <p>Le Shilajit Puramina est un trésor millénaire provenant des montagnes de l'Himalaya. Réputé pour sa pureté et ses nombreux bienfaits pour la santé, ce produit est enrichi de minéraux et d'acides fulviques essentiels. Utilisé depuis des millénaires dans la médecine ayurvédique, le Shilajit aide le corps à s'adapter au stress et à retrouver son équilibre naturel. Découvrez comment Puramina capture l'essence de ce remède ancien pour vous offrir un produit naturel et puissant.</p>
        </div>

        <div>
          <h2>Qu'est-ce que le Shilajit ?</h2>
          <p>Le Shilajit est une résine minérale rare, formée par la décomposition lente de plantes et de matières organiques sous la pression de la roche. Riche en acides fulviques et en plus de 85 minéraux, il est utilisé depuis des siècles pour ses propriétés revitalisantes. Le Shilajit améliore l'énergie, la santé cognitive, et soutient le système immunitaire. Il est également reconnu pour ses effets anti-inflammatoires et antioxydants, faisant de lui un remède complet pour la santé globale.</p>
        </div>

        <div>
          <h2>Origine du Shilajit Puramina</h2>
          <p>Le Shilajit Puramina est récolté dans les altitudes élevées de l'Himalaya. Ce cadre naturel préservé garantit une qualité exceptionnelle. Les conditions extrêmes de l'Himalaya, avec son air pur et ses eaux claires, contribuent à la formation d'un Shilajit d'une pureté inégalée. Nos équipes utilisent des méthodes traditionnelles respectueuses de l'environnement pour garantir une récolte durable et un produit pur. Cette approche artisanale assure que seul le meilleur Shilajit est sélectionné pour Puramina.</p>
        </div>

        <div>
          <h2>Le Processus de Récolte du Shilajit Puramina</h2>
          <p>Description détaillée de la récolte du Shilajit, incluant les méthodes traditionnelles et respectueuses de l'environnement.</p>
          <p>Saison et conditions de récolte</p>
          <p>Techniques manuelles de récolte</p>
        </div>

        <div>
          <h2>Filtration et Purification du Shilajit Puramina</h2>
          <p>Description du processus de filtration et de purification pour garantir la qualité et la pureté du produit.</p>
          <p>Méthodes de filtration naturelle</p>
          <p>Contrôle qualité et tests de pureté</p>
        </div>

        <div>
          <h2>Transformation et Conditionnement du Shilajit Puramina</h2>
          <p>Processus de transformation du Shilajit brut en produit fini.</p>
          <p>Techniques de transformation utilisées</p>
          <p>Emballage respectueux de l'environnement et hermétique pour préserver la qualité</p>
        </div>

        <div>
          <h2>Les Bienfaits du Shilajit Puramina</h2>
          <p>Liste des principaux bienfaits pour la santé offerts par le Shilajit Puramina.</p>
          <h3>Renforcement du système immunitaire</h3>
          <p>Description des bienfaits pour le système immunitaire.</p>
          <h3>Amélioration de l'énergie et de la vitalité</h3>
          <p>Description des bienfaits pour l'énergie et la vitalité.</p>
          <h3>Soutien cognitif et mémoire</h3>
          <p>Description des bienfaits pour le soutien cognitif et la mémoire.</p>
        </div>

        <div>
          <h2>Témoignages de Clients Satisfaits</h2>
          <p>Avis et témoignages de clients sur les effets positifs du Shilajit Puramina.</p>
        </div>

        <div>
          <h2>FAQ - Tout Savoir sur le Shilajit Puramina</h2>
          <h3>Comment consommer le Shilajit Puramina ?</h3>
          <p>Instructions sur la consommation du Shilajit Puramina.</p>
          <h3>Quelle est la dose recommandée ?</h3>
          <p>Informations sur la dose recommandée de Shilajit Puramina.</p>
          <h3>Y a-t-il des contre-indications ?</h3>
          <p>Informations sur les éventuelles contre-indications.</p>
        </div>

        <div>
          <h2>Découvrez le Shilajit Puramina Aujourd'hui</h2>
          <p>Résumé des points forts du Shilajit Puramina et invitation à l'achat.</p>
          <button>Acheter Maintenant</button>
        </div>
      </CenterPuraminaPage>
      <div>
        <Fidelity />
      </div>
    </PuraminaPage>
  );
};

export default PuraminaInfos;
