import React, { useState, useRef, useEffect, useCallback } from 'react';
import { FidelityComponent, FeatureBox } from "./StyledFidelityComponent";
import { GrSecure } from "react-icons/gr";
import { IoLeafOutline } from "react-icons/io5";
import { LuPackageCheck } from "react-icons/lu";
import FooterSection from "../Footer/FooterSection";

const Fidelity = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slidesRef = useRef([]);
  const touchStartXRef = useRef(null);
  const observerRef = useRef(null);

  const features = [
    {
      Icon: GrSecure,
      title: "PAIEMENT SÉCURISÉ",
      description: "Vos transactions sont sûres et protégées."
    },
    {
      Icon: IoLeafOutline,
      title: "100% NATUREL",
      description: "Notre résine de Shilajit est pure à 100 %."
    },
    {
      Icon: LuPackageCheck,
      title: "LIVRAISON RAPIDE",
      description: "Livraison rapide à votre domicile."
    }
  ];

  const goToSlide = (index) => {
    if (index >= 0 && index < features.length) {
      setCurrentSlide(index);
      slidesRef.current[index].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    }
  };

  const handleTouchStart = (e) => {
    touchStartXRef.current = e.targetTouches[0].clientX;
  };

  const handleTouchMove = (e) => {
    if (touchStartXRef.current === null) return;

    const touchEndX = e.targetTouches[0].clientX;
    const direction = touchStartXRef.current - touchEndX;

    if (direction > 10) {
      goToSlide(currentSlide + 1);
    } else if (direction < -10) {
      goToSlide(currentSlide - 1);
    }

    touchStartXRef.current = null;
  };

  const updateCurrentSlide = useCallback((entries) => {
    const visibleSlide = entries.find(entry => entry.isIntersecting);
    if (visibleSlide) {
      const index = slidesRef.current.indexOf(visibleSlide.target);
      if (index !== currentSlide) {
        setCurrentSlide(index);
      }
    }
  }, [currentSlide]);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(updateCurrentSlide, {
      root: null,
      threshold: 0.5
    });

    slidesRef.current.forEach(slide => {
      if (slide) observerRef.current.observe(slide);
    });

    return () => {
      if (observerRef.current) {
        slidesRef.current.forEach(slide => {
          if (slide) observerRef.current.unobserve(slide);
        });
      }
    };
  }, [updateCurrentSlide]);

  // Resynchronize the slide position in case of resizing
  useEffect(() => {
    if (slidesRef.current[currentSlide]) {
      slidesRef.current[currentSlide].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    }
  }, [currentSlide]);

  return (
    <FidelityComponent>
      <div className="section-1" onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
        {features.map((feature, index) => (
          <FeatureBox
            key={index}
            ref={(el) => slidesRef.current[index] = el}
            $isSelected={index === currentSlide}
          >
            <button>
              <feature.Icon className='icons-fidelity' />
            </button>
            <h4>{feature.title}</h4>
            <p>{feature.description}</p>
          </FeatureBox>
        ))}
      </div>
      <div className="pagination">
        {features.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentSlide ? 'active' : ''}`}
            onClick={() => goToSlide(index)}
          />
        ))}
      </div>
      <div className='section-2'>
        <FooterSection />
      </div>
    </FidelityComponent>
  );
};

export default Fidelity;
