import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  UPDATE_QUANTITY,
  CLEAR_CART,
} from '../action/CartActions';

const initialState = {
  items: [],
  total: 0,
};

const calculateTotal = (items) => {
  return items.reduce((acc, item) => acc + item.price * item.quantity, 0);
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      const existingItem = state.items.find(item => item.id === action.payload.id);
      if (existingItem) {
        const updatedItems = state.items.map(item =>
          item.id === action.payload.id
            ? { ...item, quantity: item.quantity + action.payload.quantity }
            : item
        );
        const newTotal = calculateTotal(updatedItems);
        console.log("Adding to existing item:", existingItem);
        console.log("New total after adding:", newTotal);
        return {
          ...state,
          items: updatedItems,
          total: newTotal,
        };
      } else {
        const updatedItems = [...state.items, action.payload];
        const newTotal = calculateTotal(updatedItems);
        console.log("Adding new item:", action.payload);
        console.log("New total after adding:", newTotal);
        return {
          ...state,
          items: updatedItems,
          total: newTotal,
        };
      }
    case REMOVE_FROM_CART:
      const itemToRemove = state.items.find(item => item.id === action.payload);
      const updatedItemsAfterRemoval = state.items.filter(item => item.id !== action.payload);
      const newTotalAfterRemoval = calculateTotal(updatedItemsAfterRemoval);
      console.log("Removing item:", itemToRemove);
      console.log("New total after removal:", newTotalAfterRemoval);
      return {
        ...state,
        items: updatedItemsAfterRemoval,
        total: newTotalAfterRemoval,
      };
    case UPDATE_QUANTITY:
      const updatedItemsAfterQuantityChange = state.items.map(item =>
        item.id === action.payload.id ? { ...item, quantity: action.payload.quantity } : item
      );
      const newTotalAfterQuantityChange = calculateTotal(updatedItemsAfterQuantityChange);
      console.log("Updating quantity. New total:", newTotalAfterQuantityChange);
      return {
        ...state,
        items: updatedItemsAfterQuantityChange,
        total: newTotalAfterQuantityChange,
      };
    case CLEAR_CART:
      console.log("Clearing cart");
      return {
        ...state,
        items: [],
        total: 0,
      };
    default:
      return state;
  }
};

export default cartReducer;
