import styled from 'styled-components'

export const Newlaters = styled.section`
height: auto;
width: 100vw;
background: white;

display: flex;
align-items: center;
justify-content: center;
border-bottom: 1px solid white;
div {
    display: flex;
    flex-direction: column;
    width: 40%;
    p {
        margin: 40px 0 10px;
        text-align: center;
        font-family: 'Trebuchet MS', sans-serif;


    }
    input {
        height: 5vh;
        margin: 10px 0;
        background: #F5F5F5;
        border-radius: 10px;
        border: 1px solid #BDBDBD;

    }
    button {
        height: 5vh;
        margin: 10px 0 40px;
        border-radius: 10px;
        background: black;
        color: white;

        border: 1px solid black;
    }
}
justify-content: center;
@media (max-width: 900px) {
    div {
        display: flex;
        flex-direction: column;
        width: 80%;
    }
}

@media (max-width: 500px) {
    height: auto;
width: 100vw;
background: white;
color: black;
display: flex;
align-items: center;
justify-content: center;
border-bottom: none;
div {
    width: 90%;
    p {
        margin: 40px 0 10px;
        color: black;
        font-size: 14px;


    }
    input {
        height: 5vh;
        margin: 10px 0;

    }
    button {
        height: 6vh;
        margin: 10px 0 40px;

    }
}
justify-content: center;
}
`