// src/components/ApplePayButton.js
import React, { useEffect, useState } from 'react';
import { useStripe, PaymentRequestButtonElement } from '@stripe/react-stripe-js';

const ApplePayButton = () => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [canMakePayment, setCanMakePayment] = useState(false);

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'FR', // Remplacez par le pays approprié
        currency: 'eur', // Remplacez par la devise appropriée
        total: {
          label: 'Total',
          amount: 2999, // Montant total en cents
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      pr.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(pr);
          setCanMakePayment(true);
        }
      });

      pr.on('paymentmethod', async (ev) => {
        const {error, paymentMethod} = await stripe.createPaymentMethod({
          type: 'card',
          card: ev.paymentMethod,
        });

        if (error) {
          ev.complete('fail');
        } else {
          ev.complete('success');
          // Envoyer paymentMethod.id à votre serveur pour traitement
          console.log(paymentMethod);
        }
      });
    }
  }, [stripe]);

  if (canMakePayment && paymentRequest) {
    return <PaymentRequestButtonElement options={{ paymentRequest }} />;
  } else {
    return null;
  }
};

export default ApplePayButton;
