import styled, { css } from 'styled-components';

export const FidelityComponent = styled.section`
  height: auto; 
  width: 100vw;
  justify-content: center;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  z-index: 1000;

  .section-1 {
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    height: auto;
    margin-bottom: 0px;

    &::-webkit-scrollbar {
      height: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: inherit; /* Changez cette couleur en rouge */
    }
  }

  .pagination {
    display: none;
    justify-content: center;
  }

  .dot {
    padding: 3px;
    margin-right: 5px;
    cursor: pointer;
    border-radius: 50%;
    background: #C8E6C9;
    transition: background 0.3s;
    margin-bottom: 50px;

    &.active {
      background: green;
    }
  }

  @media (max-width: 1050px) {
    .section-1 {
      width: 100%;
    }
    .pagination {
      display: flex;
      justify-content: center;
    }
  }
`;

export const FeatureBox = styled.div`
  height: 20vh;
  width: 100vw;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 10px;
  flex-direction: column;
  text-align: center;
  scroll-snap-align: center;

  button {
    padding: 5px;
    border-radius: 20px;
    border: none;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: rgb(232 255 247);

    .icons-fidelity {
      font-size: 40px;
    }
  }

  h4 {
    font-size: 20px;
    padding: 10px;
    height: auto;
  }

  p {
    font-size: 18px;
    justify-content: center;
    display: flex;
    text-align: center;
    color: gray;
    padding: 10px;
  }

  .icons-fidelity {
    color: green;
  }

  @media (max-width: 1175px) {
    p {
      font-size: 15px;
    }
    h4 {
      font-size: 17px;
      padding: 10px;
      height: auto;
    }
    button {
      width: 60px;
      height: 60px;

      .icons-fidelity {
        font-size: 30px;
      }
    }
  }

  @media (max-width: 1050px) {
    width: 100vw;
    height: 20vh;
    flex: 0 0 100%;
    scroll-snap-align: center;

    ${({ $isSelected }) =>
      $isSelected &&
      css`
        border: none;
      `}
  }

  @media (max-width: 500px) {
    height: auto;
    margin: 5px;
    margin: 20px;

    button {
      padding: 5px;
      border-radius: 20px;
      border: none;
      margin-bottom: 10px;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: rgb(232 255 247);

      .icons-fidelity {
        font-size: 30px;
      }
    }

    h4 {
      font-size: 15px;
      padding: 5px;
    }

    p {
      font-size: 13px;
      padding: 5px;
    }
  }
`;
