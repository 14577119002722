import { useState, useEffect } from 'react';

const useScrollDirection = () => {
  const [navClass, setNavClass] = useState('initial');
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [isInitial, setIsInitial] = useState(true);
  const [scrollDirection, setScrollDirection] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      if (currentScrollPos === 0) {
        setIsInitial(true);
        setScrollDirection(null);
      } else if (prevScrollPos > currentScrollPos) {
        setIsInitial(false);
        setScrollDirection('up');
      } else {
        setIsInitial(false);
        setScrollDirection('down');
      }
      setPrevScrollPos(currentScrollPos); // Update after determining the scroll direction
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  useEffect(() => {
    const updateNavClass = () => {
      console.log('isInitial:', isInitial);
      console.log('scrollDirection:', scrollDirection);
      if (isInitial) {
        setNavClass('initial');
      } else if (scrollDirection === 'up') {
        setNavClass('visible');
      } else if (scrollDirection === 'down') {
        setNavClass('hidden');
      }
    };

    updateNavClass();
  }, [scrollDirection, isInitial]);

  return navClass;
};

export default useScrollDirection;
