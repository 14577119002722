import styled from 'styled-components';

export const ProgressBarWrapper = styled.div`
position: absolute;
top: 17%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  height: 15vh;
@media (max-width: 850px) {
    width: 70%;

}
@media (max-width: 500px) {
    width: 100%;

}
`;

export const ProgressBarContainer = styled.div`
  width: 75%;
  background-color: #e0e0e0;
  display: flex;
  justify-content: center;
  border-radius: 13px;
  overflow: hidden;
  margin: 10px 0;
  position: relative;
`;

export const Progress = styled.div`
  width: ${props => props.width}%;
  height: 10px;
  background-color: #4caf50;
  transition: width 0.3s;
`;

export const Message = styled.p`
  margin: 10px 30px 0px 30px;
  font-size: 14px;
  color: ${props => props.color || 'black'};
  text-align: center;
  line-height: 1.7;
  width: 80%;
  padding: 10px;
  border-radius: 10px;
  font-weight: 800;
`;

export const HighlightedText = styled.span`
  background-color: #DCEDC8;
  color: black;
  font-weight: 800;
  padding: 5px;
  border-radius: 5px;
  font-size: 18px;
`;