import styled from 'styled-components'

export const PuraminaPage = styled.section`
height: auto;

`


export const CenterPuraminaPage = styled.div`
height: auto;
background: red;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
padding-top: 200px;
div {
    background: green;
    margin: 10px;
    width: 60%;

}

`
