import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { setCookie } from '../../utils/cookieHelper'; // Importer setCookie
import {
  CommandeContainer,
  Section2,
  Section4,
  NameFields,
  LocationFields,
  SummaryToggle,
  OrderSummary,
  PaymentSection,
  GlobalComposent,
} from './StyledCommande';
import FloatingLabelInput from '../input/FloatingLabelInput';
import StripeContainer from '../../stripe/StripeContainer';

const CommandeForm = () => {
  const [isSummaryOpen, setIsSummaryOpen] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [city, setCity] = useState('');
  const [errors, setErrors] = useState({});
  const cart = useSelector(state => state.cart.items);
  const totalAmount = useSelector(state => state.cart.total);

  const toggleSummary = () => {
    setIsSummaryOpen(!isSummaryOpen);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!lastName) newErrors.lastName = 'Entrez un nom';
    if (!address) newErrors.address = 'Entrez une adresse';
    if (!postalCode) newErrors.postalCode = 'Entrez un code postal';
    if (!city) newErrors.city = 'Saisissez une ville';
    setErrors(newErrors);
    console.log("Validation des champs du formulaire:", newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const isFormValid = validateForm();
    if (isFormValid) {
      // Stocker les données du formulaire dans localStorage
      localStorage.setItem('formData', JSON.stringify({ firstName, lastName, address, postalCode, city }));

      // Définir les cookies
      setCookie('firstName', firstName, 7);
      setCookie('lastName', lastName, 7);
      setCookie('address', address, 7);
      setCookie('postalCode', postalCode, 7);
      setCookie('city', city, 7);

      return true;
    }
    return false;
  };

  return (
    <GlobalComposent>
      <CommandeContainer>
        <PaymentSection>
          <SummaryToggle onClick={toggleSummary}>
            <span>{isSummaryOpen ? 'Fermer le sommaire de la commande' : 'Vérifier le sommaire de la commande'}</span>
            <span>{totalAmount.toFixed(2)} €</span>
          </SummaryToggle>
        </PaymentSection>
        {isSummaryOpen && (
          <OrderSummary>
            {cart.map(item => (
              <div key={item.id} className='top-sommaire'>
                <p>{item.quantity}</p>
                <img src={item.image} alt={item.name} />
                <div>
                  <p>{item.name}</p>
                  <p>{item.description}</p>
                </div>
                <p>{(item.price * item.quantity).toFixed(2)} €</p>
              </div>
            ))}
            <div>
              <p>Sous-total</p>
              <p>{totalAmount.toFixed(2)} €</p>
            </div>
            <div>
              <span>Livraison</span>
              <span>Saisir une adresse d'expédition</span>
            </div>
            <div>
              <span>Total</span>
              <span>{totalAmount.toFixed(2)} €</span>
            </div>
          </OrderSummary>
        )}

        <Section2>
          <h2>Information de Livraison</h2>
          <select>
            <option value="France">Pays/région: France</option>
          </select>
          <NameFields>
            <div className='A'>
              <FloatingLabelInput 
                type="text" 
                label="Prénom (optionnel)" 
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className='A'>
              <FloatingLabelInput 
                type="text" 
                label="Nom" 
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                style={{ borderColor: errors.lastName ? 'red' : '' }}
              />
              {errors.lastName && <p style={{ color: 'red', fontSize: '13px' }}>{errors.lastName}</p>}
            </div>
          </NameFields>
          <div>
            <FloatingLabelInput 
              type="text" 
              label="Adresse" 
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              style={{ borderColor: errors.address ? 'red' : '' }}
            />
            {errors.address && <p style={{ color: 'red', fontSize: '13px' }}>{errors.address}</p>}
          </div>
          <div>
            <FloatingLabelInput 
              type="text" 
              label="Appartement, suite, etc. (optionnel)" 
            />
          </div>
          <LocationFields>
            <div>
              <FloatingLabelInput 
                type="text" 
                label="Code postal" 
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
                style={{ borderColor: errors.postalCode ? 'red' : '' }}
              />
              {errors.postalCode && <p style={{ color: 'red', fontSize: '13px' }}>{errors.postalCode}</p>}
            </div>
            <div>
              <FloatingLabelInput 
                type="text" 
                label="Ville" 
                value={city}
                onChange={(e) => setCity(e.target.value)}
                style={{ borderColor: errors.city ? 'red' : '' }}
              />
              {errors.city && <p style={{ color: 'red', fontSize: '13px' }}>{errors.city}</p>}
            </div>
          </LocationFields>
        </Section2>

        <Section4>
          <p>Toutes les transactions sont sécurisées et chiffrées.</p>
          <StripeContainer handleSubmit={handleFormSubmit} />
        </Section4>
      </CommandeContainer>
    </GlobalComposent>
  );
};

export default CommandeForm;
