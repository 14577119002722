// src/components/StyledProduictInfos.js
import styled from 'styled-components';

export const Infos = styled.section`
  font-family: 'Roboto', sans-serif;
  background: rgb(25, 25, 25);
  padding: 15px;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .Infos-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;
    margin: 0 auto;
  }

  .Infos-1, .Infos-2, .Infos-3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
    margin: 20px ;
  }
  .Infos-2 {
    flex-direction: row-reverse;

  }

  .Shilajit-image,
  .Shilajit-infos,
  .Shilajit-image-2,
  .Shilajit-infos-2,
  .Shilajit-image-3,
  .Shilajit-infos-3 {
    margin: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .Shilajit-image img,
  .Shilajit-image-2 div,
  .Shilajit-image-3 p {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 15px;
  }

  .Shilajit-infos,
  .Shilajit-infos-2,
  .Shilajit-infos-3 {
    background: white;
    padding: 20px; 
    border-radius: 15px;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h3 {
    color: gray;
    margin-bottom: 10px;
    padding: 10px 10px 0 10px; 
  }

  .Titre {
    font-size: 24px;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    line-height: 1.7;
    letter-spacing: 0.5px;
    font-family: 'Trebuchet MS', sans-serif;
  }

  @media (max-width: 1200px) {
    .Infos-1, .Infos-2, .Infos-3 {
      flex-direction: column;

      width: 60%;
    }

  }

  @media (max-width: 900px) {
    .Shilajit-image,
    .Shilajit-infos,
    .Shilajit-image-2,
    .Shilajit-infos-2,
    .Shilajit-image-3,
    .Shilajit-infos-3 {
      margin: 20px;
      width: 100%;
      padding: 0px;
    }
    .Infos-1, .Infos-2, .Infos-3 {
      flex-direction: column;

      width: 80%;
    }
  }

  @media (max-width: 500px) {
    .Shilajit-image,
    .Shilajit-infos,
    .Shilajit-image-2,
    .Shilajit-infos-2,
    .Shilajit-image-3,
    .Shilajit-infos-3 {
      margin: 20px;
      width: 100%;
      padding: 0px;
    }
    .Infos-1, .Infos-2, .Infos-3 {
      width: 100%;

    }


    h3 {
      font-size: 18px;
    }

    .Titre {
      font-size: 20px;
    }

    p {
      font-size: 14px;
      line-height: 1.5;
      margin: 5px;
    }
  }
`;
