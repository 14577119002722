// src/components/CartBadge.js
import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

const Badge = styled.div`
  position: relative;
  display: inline-block;
  padding: 10px;

  .cart-icon {
    font-size: 30px; /* Ajustez selon vos besoins */
  }

  .badge {
    position: absolute;
    background-color: red;
    top: 0;
    right: 0;
    color: white;
    border-radius: 50%;
    width: 20px; /* Ajustez selon vos besoins */
    height: 20px; /* Ajustez selon vos besoins */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px; /* Ajustez selon vos besoins */
  }
`;

const CartBadge = ({ icon: Icon }) => {
  const cart = useSelector((state) => state.cart);

  return (
    <Badge>
      <Icon className="cart-icon" />
      {cart.items.length > 0 && <span className="badge">{cart.items.length}</span>}
    </Badge>
  );
};

export default CartBadge;
