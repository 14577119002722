import React, {useState} from 'react'
import {ShilajitOffre} from "./StyledOffers"
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { IoIosArrowDropup } from "react-icons/io";

const Offers = () => {
    const [showMore, setShowMore] = useState(false);
  

  
  return (
    <ShilajitOffre >
        <div className='title-infos-golbal'>
            <p className='title-infos'>Énergie Renouvelée 🔥</p>
            <p className='title-info2'>Augmentez Votre Énergie Quotidienne avec PuraMina - 100% Naturelle et Pure.</p>
    
            {showMore && (
                <>
                    <p className='title-infos'>Virilité Revitalisée 💪</p>
                    <p className='title-info2'>Revitalisez Votre Virilité - Soutien Naturel à la Testostérone.</p>
                    <p className='title-infos'>Équilibre Hormonal Féminin 🌿</p>
                    <p className='title-info2'>Harmonisez Votre Cycle - Bien-Être Féminin Optimisé.</p>
                    <p className='title-infos'>Sérénité Quotidienne 🧘</p>
                    <p className='title-info2'>Profitez d'une Sérénité Quotidienne - Réduction du Stress Naturelle.</p>
                </>
            )}
        </div>
        <button onClick={() => setShowMore(!showMore)} className='button-plus'>
            {showMore ? <> <IoIosArrowDropup className='icons-infos'/> <p>Réduire</p> </> : <> <IoIosArrowDropdownCircle className='icons-infos'/> <p>plus</p> </>}
        </button>
        
    </ShilajitOffre>
  )
}

export default Offers