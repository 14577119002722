import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotateAndGrow = (growScale) => keyframes`
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(${1 + (growScale + 1) / 2});
  }
  100% {
    transform: rotate(360deg) scale(${growScale}) translateY(10px);
  }
`;

const rotate = keyframes`
  0% {
    transform: rotate(30deg) scale(1);
  }
  70% {
    transform: rotate(140deg) scale(1);
  }
  100% {
    transform: rotate(360deg) scale(2);
  }
`;

const StyledImage = styled.img`
  object-fit: cover;
  display: block;
  border-radius: 50px;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: ${({ width }) => width || '15vw'};
  height: ${({ height }) => height || '15%'};
  animation: ${({ $startGrow, $growScale }) => $startGrow ? rotateAndGrow($growScale || 1.2) : rotate} 2s linear infinite;
  margin: 30px;

  @media (max-width: 1300px) {
    width: 7vw;
    height: 10vh;
  }

  @media (max-width: 1180px) {
    width: 9vw;
    height: 12vh;
  }
  @media (max-width: 1050px) {
    width: 11vw;
    height: 12vh;
  }

  @media (max-width: 900px) {
    width: 7vw;
    height: 7vh;
  }
  @media (max-width: 830px) {
    width: 8vw;
    height: 7vh;
  }

  @media (max-width: 700px) {
    width: 8vw;
    height: 6vh;
  }
  @media (max-width: 603px) {
    width: 9vw;
    height: 6vh;
  }
  @media (max-width: 510px) {
    width: 9vw;
    height: 5vh;
  }
  @media (max-width: 430px) {
    width: 10vw;
  }
  @media (max-width: 387px) {
    width: 11vw;
    height: 5vh;
  }
  `;

const RotatingImage = ({ src, alt, width, height, startGrow, growScale, className }) => {
  return <StyledImage src={src} alt={alt} width={width} height={height} $startGrow={startGrow} $growScale={growScale} className={className} />;
};

export default RotatingImage;
