import React from 'react'
import styled from 'styled-components'


const Title = styled.div`
position: absolute;
top: 10%;
font-size: 22px;
font-weight: 800;
height: 10vh;
p {
    padding-bottom: 7px;
}
span {
  background: #a5d6a7;
  border-radius: 15px;
  padding: 5px;
  color: #388e3c;
  border: 1.5px solid black;
}
`;

const TitleModal = () => {
  return (
    <Title>
        <p>Panier de <span>Commande</span></p>
    </Title>
  )
}

export default TitleModal