import styled from 'styled-components';

export const FAQQuestionReponse = styled.section`
  height: auto;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
    z-index: 1000;
    


  h4 {
    margin: 80px 0 20px;
    font-size: 50px;
      font-family: 'Trebuchet MS', sans-serif;

  }

  p {
    margin-bottom: 60px;
    font-size: 18px;
    color: #1A1A1AB3;
    line-height: 1.5;
      font-family: 'Trebuchet MS', sans-serif;

  }

  .horaire-reponse {
    margin-top: 60px;
    text-align: center;
    color: #1A1A1AB3;
    
  }

  @media screen and (max-width: 500px) {
    h4 {
      margin: 80px 0 20px;
      font-size: 25px;
    }

    p {
      text-align: center;
      margin: 5px 10px 60px 10px;
      font-size: 17px;
      color: gray;
      line-height: 1.5;
    }

    .horaire-reponse {
      margin-top: 60px;
      text-align: center;
      color: #1A1A1AB3;
      font-size: 13px;
      width: 80vw;
      
    }
  }
`;

export const SectionFAQ = styled.section`
  width: 75vw;
  height: auto;

  .faq-item {
    border-bottom: 1px solid #ddd;

  }

  .faq-answer-container {
    overflow: hidden;
    transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
  }

  .faq-answer {
    padding: 10px 0 ;
    font-size: 16px;
    line-height: 1.5;
      font-family: 'Trebuchet MS', sans-serif;

  }

  .faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 10px 0 ;
    font-size: 18px;
    transition: color 0.5s ease;
    
  }

  .DDD {
    width: 90%;
      font-family: 'Trebuchet MS', sans-serif;

  }

  @media (max-width: 858px) {
    width: 95vw;

    .faq-question {
      font-size: 14px;
    }
  }

  @media (max-width: 500px) {
    width: 90vw;

    .faq-answer {
      font-size: 14px;


    }

    .faq-question {
      font-size: 15px;
      font-weight: 600;
      padding-bottom: 25px;


    }
  }

  @media (max-width: 400px) {
    .faq-question {
      margin: 5px 0 0px 0;
    }
  }
`;
