import React from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import ModalBackdrop from '../modal/ModalBackdrop';
import useMenu from '../../hooks/useMenu';
import { useModal } from '../../contexts/ModalContext';
import { LuShoppingBasket } from 'react-icons/lu';
import { NavGlobale, ResponsiveMenu, NavItem, MenuIcon } from './StyledNavBar';
import CartBadge from '../panier/panierBadge/CartBadge';
import useButtonCommande from '../../hooks/ButtonCommande';
import PuraminaNav from '../../assets/image/PuraminaNav.png';
import useNavScroll from '../../hooks/ScrollDirection';

const NavBar = ({ isPaymentPage }) => {
  const { isMenuOpen, toggleMenu, closeMenu } = useMenu();
  const { handleOpenModal } = useModal();
  const { pageAccueil, PuraminaPage } = useButtonCommande();
  const navClass = useNavScroll();



  return (
    <NavGlobale className={`${navClass} ${isPaymentPage ? 'payment-page' : ''}`}>
      <button className='button-puramina' onClick={pageAccueil}>
        <img src={PuraminaNav} alt="Shilajit Marque" className="image-responsive" />
      </button>
      <NavItem onClick={pageAccueil}>Accueil</NavItem>
      <NavItem className='nav-left' onClick={PuraminaPage}>Produits</NavItem>
      <NavItem onClick={handleOpenModal}>
        <CartBadge className='icon-nav' icon={LuShoppingBasket} />
      </NavItem>
      <MenuIcon onClick={toggleMenu}>
        <GiHamburgerMenu className='MenuIcon-1' />
      </MenuIcon>
      {isMenuOpen && (
        <ModalBackdrop onClick={closeMenu}>
          <ResponsiveMenu $isExpanded={isMenuOpen}>
            <button onClick={pageAccueil}>Accueil</button>
            <button onClick={handleOpenModal}>PANIER</button>
            <button onClick={PuraminaPage}>Produit</button>
          </ResponsiveMenu>
        </ModalBackdrop>
      )}
    </NavGlobale>
  );
};

export default NavBar;