import React, { useEffect, useState } from 'react';
import {
  LoaderWrapper,
  Loader,
  LoadingText,
  ProgressBar,
  Progress,
  Percentage
} from './StyledLoadingPage';
import RotatingImage from './RotatingImage';
import LogotPuramina from '../../assets/image/LogotPuramina.png';

const LoadingPage = () => {
  const [progress, setProgress] = useState(0);
  const [barFullWidth, setBarFullWidth] = useState(false);
  const [loadingComplete, setLoadingComplete] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress < 100) {
          return prevProgress + 1;
        } else {
          clearInterval(interval);
          setBarFullWidth(true);
          setTimeout(() => {
            setLoadingComplete(true);
          }, 1000); // Délai pour s'assurer que la barre est visible à 100% avant de terminer le chargement
          return 100;
        }
      });
    }, 15); // Progression plus lente pour la visibilité
    return () => clearInterval(interval);
  }, []);

  if (loadingComplete) {
    return null; // Ne pas rendre LoaderWrapper si le chargement est terminé
  }

  return (
    <LoaderWrapper>
      <RotatingImage src={LogotPuramina} alt="logot" width="5vw" height="8vh" growScale={2.5} className="logotLoading" />
      <Loader $progress={progress}>
        <LoadingText $progress={progress}>PuraMina Shilajit...</LoadingText>
        <ProgressBar $fullWidth={barFullWidth}>
          <Progress style={{ width: `${progress}%` }} />
        </ProgressBar>
        <Percentage $progress={progress}>{progress}%</Percentage>
      </Loader>
    </LoaderWrapper>
  );
};

export default LoadingPage;
