// src/components/page/ProductPackOption.js
import React from 'react';
import { ButtonCommande, BlinkingText} from './StyledProductPack';

const ProductPackOption = ({ pack, onSelect, isBestOffer, isSelected, title }) => {
  return (
      <ButtonCommande
        onClick={() => onSelect(pack)}
        $isSelected={isSelected} // Changer la couleur de fond si sélectionné
      >
        <div>
          {isBestOffer ? (
            <BlinkingText>+ 1 Pot Offert 🎁</BlinkingText>
          ) : (
            <BlinkingText className="blink-text">100% PURE 🌿</BlinkingText>
          )}
          <span className='title'> {pack.title}</span>
        </div>
        <span className="price">{pack.price.toFixed(2)}€</span>
        <span className="button-title">{title}</span>
      </ButtonCommande>
  );
};

export default ProductPackOption;