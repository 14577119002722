import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';

const PUBLIC_KEY = "pk_live_51PKp46DnulWdQbnv9BxV0OObjQaG1tUnXoA30aEG8sQeoRfgJePua4WCHxrsJLoe7WIPaRud92p4iSYOyNagMpFh00GO1iADOI";
const stripeTestPromise = loadStripe(PUBLIC_KEY);

const StripeContainer = ({ handleSubmit }) => {
    console.log("Passage de handleSubmit à CheckoutForm");
    return (
      <Elements stripe={stripeTestPromise}>
        <CheckoutForm handleSubmit={handleSubmit} />
      </Elements>
    );
  };
  

export default StripeContainer;