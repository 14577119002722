import { useState, useEffect } from 'react';

const useFloatingLabel = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  useEffect(() => {
    setIsFilled(value !== '');
  }, [value]);

  return {
    value,
    setValue,
    isFocused,
    setIsFocused,
    isFilled,
  };
};

export default useFloatingLabel;
