import styled from 'styled-components';

export const ShilajitOffre = styled.section`
  height: auto;
  width: 100vw;
  padding: 20px;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #333;
  

  z-index: 1000;

  div {
    width: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background: #9CCC65;
    border: 1.5px dashed black;
    

    .title-infos-golbal {
      border: 2px solid #ddd;
      padding: 15px;
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
      margin: 10px 0;
      letter-spacing: 2px;
      
    }
    .title-infos {
        font-size: 22px;
        font-weight: bold;
        color: white;
        margin-bottom: 10px;
        
      }

    .title-info2 {
      font-size: 14px;
      color: #555;
      background: #F1F8E9;
      padding: 0 5px;
      margin-bottom: 10px;
      border: 2px solid white;
      border-radius: 8px;
      height: 5vh;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Trebuchet MS', sans-serif;


    }
  }

  .button-plus {
    margin: 5px;
    color: #9CCC65;
    border: 2px solid #9CCC65;
    background: #F1F8E9;
    font-size: 18px;
    padding: 7px 15px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;

    &:hover {
      background-color: #F1F8E9;
      color: #9CCC65;
    }

    .icons-infos {
      color: #9CCC65;
      height: 27px;
      width: 27px;
      margin-right: 8px;
    }
  }

  @media screen and (max-width: 800px) {
    div {
      width: 80%;
    }
  }

  @media screen and (max-width: 500px) {
    div {
      width: 85%;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      background: #9CCC65;
      border: 1.5px dashed black;

      .title-infos-golbal {
        border: 2px solid #ddd;
        padding: 15px;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
        margin: 10px 0;
        letter-spacing: 2px;
      }

      .title-infos {
        font-size: 18px;
        font-weight: bold;
        color: white;
        margin-bottom: 10px;
      }

      .title-info2 {
        font-size: 14px;
        color: #555;
        background: #F1F8E9;
        padding: 0 5px;
        margin-bottom: 10px;
        border: 2px solid white;
        border-radius: 8px;
        height: 5vh;
        width: 100%;
        display: flex;
        align-items: center;
      }
    }

    .button-plus {
      margin: 5px;
      color: #9CCC65;
      border: 2px solid #9CCC65;
      background: #F1F8E9;
      font-size: 18px;
      padding: 7px 15px;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      align-items: center;
      transition: all 0.3s ease;

      &:hover {
        background-color: #F1F8E9;
        color: #9CCC65;
      }

      .icons-infos {
        color: #9CCC65;
        height: 24px;
        width: 24px;
        margin-right: 8px;
      }
    }
  }

  @media screen and (max-height: 700px) {
    .title-infos-golbal .title-info2 {
      padding: 8px 3px 8px 3px;
      height: 6vh;
    }
  }

  @media screen and (max-width: 501px) {
    div {
      padding: 10px 15px;

      .title-infos {
        font-size: 14px;
        color: black;
      }

      p {
        font-size: 10px;
        margin: 3px;
      }
    }

    .button-plus {
      color: gray;
      background-color: inherit;
      font-size: 10px;

      .icons-infos {
        height: 2vh;
        width: 5vw;
      }
    }
  }
`;
