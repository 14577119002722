// components/DivVerte.js
import React from 'react';
import styled from 'styled-components';

const GreenDeployContainer2 = styled.div`
display: flex;
  justify-content: center;
  align-items: center;
  position: absolute; 
  top: 65%;
  width: 40vw;
  height: 13vh;
  background: linear-gradient(135deg, #76b852 0%, #8DC26F 100%);
  border-radius: 100px;
  @media (min-width: 1299px) {
  }
  @media (max-width: 850px) {
    width: 40vw;
  height: 10vh;
  }
  @media (max-width: 550px) {
    font-size: 13px;
    width: 70vw;
  }
  @media (max-width: 400px) {
    height: 8vh;
  }
`;

const GreenDeploy2 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 800;
  font-size: 20px;
  text-align: center;
  background: linear-gradient(135deg, #76b852 0%, #8DC26F 100%);
  width: 100%;
  height: 10vh;

  @media (max-width: 550px) {
    font-size: 19px;
    width: 100%;
  }
  @media (max-width: 400px) {
    font-size: 17px;
    height: auto;
  }

  &:hover {
    color: rgba(255, 255, 255, 1);
  }
`;

const DivVerte = () => {
  return (
    <GreenDeployContainer2>
      <GreenDeploy2>
        swipe pour découvrir
      </GreenDeploy2>
    </GreenDeployContainer2>
  );
};

export default DivVerte;
