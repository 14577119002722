import React from 'react'
import {Newlaters} from './StyledNewslaters'

const MailNews = () => {
  return (
    <Newlaters>
        <div>
            <p>Entrez votre adresse mail pour obtenir des offres exclusives réservées aux abonnés fidèles</p>
            <input type='text' placeholder='Entrez'></input>
            <button>Valider</button>
        </div>
    </Newlaters>
  )
}

export default MailNews