// src/components/page/ProductPackSelection.js
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addToCart } from '../../redux/action/CartActions';
import ProductPackOption from './ProductPackOption';
import CartModal from '../modal/CartModal';
import { StyledProductPackSelection } from './StyledProductPack';
import packs from '../../data/Packs'; // Importer les packs
import { useModal } from '../../contexts/ModalContext';
import ImageGallery from '../ImageGallery/ImageGallery';
import PuraminaHome from '../../assets/image/PuraminaHome.jpg';
import ShilajitPur from '../../assets/image/ShilajitPur.webp';
import Payment from '../../assets/image/Payment.png';
import { PiBasketLight } from "react-icons/pi";

const ProductPackSelection = () => {
  const [selectedPack, setSelectedPack] = useState(packs[1]); // Sélection par défaut du pack 2 pots
  const dispatch = useDispatch();
  const { isModalVisible, handleOpenModal, handleCloseModal } = useModal(); 
  const images = [PuraminaHome, ShilajitPur, PuraminaHome, PuraminaHome];

  const handleSelectPack = (pack) => {
    setSelectedPack(pack);
  };

  const handleAddToCart = () => {
    if (selectedPack) {
      dispatch(addToCart(selectedPack));
      handleOpenModal();
    }
  };

  return (
    <StyledProductPackSelection>
      <div className="A">
        <ImageGallery images={images} />
        <p className="Boom">Prêt à transformer votre santé et votre bien-être ? Commandez Votre Shilajit PuraMina maintenant.</p>

        {packs.map((pack) => (
          <ProductPackOption
            key={pack.id}
            pack={pack}
            onSelect={handleSelectPack}
            isBestOffer={pack.id === 2}
            isSelected={selectedPack.id === pack.id} // Passer l'état sélectionné
            title={pack.id === 1 ? "Pack Test" : `Pack Premium`} // Passer le titre approprié
          />
        ))}
        <div className="B">
          <button onClick={handleAddToCart} className="D">Ajouter au panier</button>
          <button className="C" onClick={handleOpenModal}><PiBasketLight /></button>
          
        </div>
        <img src={Payment} alt="paiementimage" />
        <p className="paragraphe-end">LIVRAISON OFFERTE pour tous les produits.</p>
        <p className="paragraphe-end-2">Stock Limité.</p>
      </div>
      {isModalVisible && (
        <CartModal onClose={handleCloseModal} packs={packs} /> // Passer les packs à la modal
      )}
    </StyledProductPackSelection>
  );
};

export default ProductPackSelection;