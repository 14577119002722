import styled, { keyframes } from 'styled-components';

// Animation pour faire clignoter un texte
const blink = keyframes`
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
`;

// Styles principaux pour la sélection du pack produit
export const StyledProductPackSelection = styled.section`
color: #1A1A1A;  
height: auto;
  width: 100vw;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px 0 30px 0;
  background: #f4f4f4;
  text-align: center;

  .A {
    border-radius: 15px;
    border: 0.5px solid gray;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70vw;
    background: white;
    z-index: 1;
    padding-top: 25px;


  }
  .B {
    display: flex;
    flex-direction: row;
    .D {
      width: 52vw;
      height: 10vh;
      background: rgb(0, 0, 0);
      color: white;
      border-radius: 15px 0px 0px 15px;
      border: none;
      font-size: 20px;
    }
    .C {
      width: 11vw;
      height: 10vh;
      background: rgb(0, 0, 0);
      color: white;
      border-radius: 0px 15px 15px 0px;
      border: none;
      border-left: 1px solid white;
      font-size: 30px;



    }
  }
  .Boom {
    font-size: 15px;
    margin: 40px 0 20px;;
    font-family: "Shippori Antique B1", sans-serif;
    color: gray;

  }

  .p-1 {
    margin: 10px;
    border: 1px solid black;
    span {
      font-size: 21px;
      color: black;
      font-weight: 900;
    }
  }
  .paragraphe-end {
    margin: 20px 0 10px 0;
    color: gray;
    font-family: 'Trebuchet MS', sans-serif;


  }
  .paragraphe-end-2 {
    color: black;
    margin: 0px 0 20px 0;
    border-radius: 10px;
    color: black;

  }

  img {
      width: 15vw;
      border-radius: 11px;
      border: none;
      margin-top: 10px;
    }
    @media screen and (max-width: 1300px) {
      .A {
        width: 90%;
      }
      .B {
        .D {
          width: 61vw;
          height: 9vh;
          background: rgb(0, 0, 0);
          color: white;
          border-radius: 15px 0px 0px 15px;
          border: none;
          font-size: 20px;
        }
        .C {
          width: 20vw;
          height: 9vh;
          background: rgb(0, 0, 0);
          color: white;
          border-radius: 0px 15px 15px 0px;
          border: none;
          border-left: 1px solid white;
          font-size: 30px;
        }
      }
      img {
        width: 18vw;
        border-radius: 11px;
      border: none;
        margin-top: 10px;
      }
    }
    @media screen and (max-width: 800px) {
      .A {
        width: 90%;
      }
      .B {
        .D {
          width: 60vw;
          height: 7vh;
          color: white;
          border-radius: 15px 0px 0px 15px;
          border: none;
          font-size: 17px;

        }
        .C {
          width: 20vw;
          height: 7vh;
          color: white;
          border-radius: 0px 15px 15px 0px;
          border: none;
          border-left: 1px solid white;
          font-size: 25px;
        }
      }
      img {
        width: 20vw;
        border-radius: 11px;
      border: none;
        margin-top: 10px;
      }
    }
    @media screen and (max-width: 750px) {
      img {
        width: 30vw;
        border-radius: 11px;
      border: none;
        margin-top: 10px;
      }
    }
  // Adaptations responsives pour les petits écrans
  @media screen and (max-width: 500px) {
    img {
      width: 35vw;
      border-radius: 11px;
    border: none;
      margin-top: 10px;
    }
    .A {
        width: 90vw;
        padding: 10px;
      }
      .paragraphe-end {
        font-size: 14px;
        margin: 20px 0 10px 0;
        color: gray;
      }
      .paragraphe-end-2 {
        margin: 0px 0 20px 0;
        color: black;
        font-family: 'Trebuchet MS', sans-serif;

    
      }
      .Boom {
    font-size: 13px;
    margin: 30px 0 0px;
    font-family: "Shippori Antique B1", sans-serif;
    color: gray;
    height: auto;
    letter-spacing: 0.6px;
    line-height: 1.6; 

  }
  .B {
    .D {
      width: 70vw;
      height: 7vh;
      color: white;
      border-radius: 15px 0px 0px 15px;
      border: none;
      font-size: 15px;

    }
    .C {
      width: 20vw;
      height: 7vh;
      color: white;
      border-radius: 0px 15px 15px 0px;
      border: none;
      border-left: 1px solid white;
      font-size: 20px;


    }

    .p-1 {
      margin: 5px;
      font-size: 10px;
      color: gray;
      border: none;
      span {
        font-size: 14px;
        color: gray;
      }
    }
  }
  @media screen and (max-height: 850px)  {
    .B {
      .C {
        height: 8vh;
      }
      .D {
        height: 8vh;
      }
    }
  @media screen and (max-height: 750px)  {
    .B {
      .C {
        height: 9vh;
      }
      .D {
        height: 9vh;
      }
  }
  @media screen and (max-height: 650px)  {
    .B {
      .C {
        height: 10vh;
      }
      .D {
        height: 10vh;
      }
  }


`;

// Composant pour le texte clignotant avec l'animation blink
export const BlinkingText = styled.p`

display: flex;
align-items: center; 
justify-content: center;
  animation: ${blink} 3s infinite;
  margin-bottom: 5px;
  color: #2E7D32;
  font-size: 14px;
  font-weight: bold; // Attention: 'bolt' n'est pas valide, cela devrait être 'bold'
border: 1px solid #81C784;
background: #C8E6C9;
padding: 1px 5px 1px 5px;
border-radius: 7px;
width: 10vw;
height: 3vh;
@media screen and (max-width: 1000px) {
  width: 15vw;
  height: 3vh;
}
@media screen and (max-width: 800px) {
  width: 15vw;
  height: 2vh;
  font-size: 12px;
}
@media screen and (max-width: 615px) {
  width: 19vw;
  height: 2vh;
  font-size: 12px;
}


  @media screen and (max-width: 510px) {
    width: 25vw;
    height: auto;

    animation: ${blink} 3s infinite;
  margin-bottom: 5px;
  color: #2E7D32;
  font-size: 11px;
  font-weight: bold; // Attention: 'bolt' n'est pas valide, cela devrait être 'bold'
border: 1px solid #81C784;
background: #C8E6C9;
border-radius: 7px;
  }
  @media screen and (max-width: 340px) {
    font-size: 9px;
    width: 28vw;

  }


`;

// Style du bouton avec animation de battement de coeur
export const ButtonCommande = styled.button`
border: ${(props) => (props.$isSelected ? '2.5px solid #015136' : 'none')};
  cursor: pointer;
  background-color: ${(props) => (props.$isSelected ? '#e8fff7' : 'white')}; // Utiliser props pour la couleur de fond
      position: relative;

  justify-content: space-between;
  align-items: center;
  width: 90%;
  height: 10vh;
  border-radius: 10px;
  overflow: hidden;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  color: black;
  padding: 15px;

  div {
    display: flex;
      align-items: center;
      flex-direction: column;
      height: auto; // aligner les éléments enfants à gauche
    border: none;
    background: inherit;
    
  }


  .title, .price {
    position: relative;
    margin-right: 10px;

  }
  .button-title {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 13px;
    font-weight: bold;
    padding: 2px 5px;
    border-bottom: 3px solid #f4f4f4;
    border-left: 3px solid #f4f4f4;
    border-radius: 0px 0 0 10px;
    background: white;
    color: #9E9E9E;
  }
  @media screen and (max-width: 1300px) {

    padding: 0 10px;
    width: 90%;
    margin: 10px 0;
    height: 9vh;

    .title, .price {
      font-size: 16px;
  
    }
    font-size: 13px;
    div {
      display: flex;
      align-items: center;
      flex-direction: column;
      height: auto;
      }
    .price {
      right: 0%;
      margin-right: 5px;
      font-size: 15px;
      color: black;
      font-weight: bold;
    }
  }
  @media screen and (max-width: 800px) {

    padding: 0 10px;
    width: 80vw;
    margin: 10px 0;
    height: 7vh;
    font-size: 13px;


    .title, .price {
      font-size: 14px;
      
  
    }
    div {
      display: flex;
      align-items: center;
      flex-direction: column;
      height: auto;
      }
    .price {
      right: 0%;
      margin-right: 5px;
      font-size: 11px;
      color: black;
      font-weight: bold;
    }
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    font-size: 13px;


    .title{
      color: black;

     .price {
      font-size: 13px;
    }
  }
    
  }
  @media screen and (max-width: 400px) {

    .title, .price {
      font-size: 11px;
    }
  }
  @media screen and (max-height: 850px)  {
    height: 8vh;
  }
  @media screen and (max-height: 750px)  {
    height: 9vh;
  }
  @media screen and (max-height: 650px)  {
    height: 10vh;
  }


`;