// src/hooks/ButtonCommande.js
import { useNavigate } from 'react-router-dom';

const useButtonCommande = () => {
  const navigate = useNavigate();

  const buttonCommande = () => {
    navigate('/commande');
  };

  const pageAccueil = () => {
    navigate('/');
  };

  const PuraminaPage = () => {
    navigate('/PuraminaPage')
  }
  const navigateToPanier = () => {
    navigate('/panier');
  };
  

  return { buttonCommande, pageAccueil,navigateToPanier, PuraminaPage};
};

export default useButtonCommande;
