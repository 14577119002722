import React, { useRef, useState, useEffect } from 'react';
import { SectionFAQ } from './StyledFAQ';
import { MdKeyboardArrowDown } from 'react-icons/md';

function FaqItem({ faq, isExpanded, onToggle }) {
  const [isHovering, setIsHovering] = useState(false);
  const [maxHeight, setMaxHeight] = useState('0px');
  const answerRef = useRef(null);

  useEffect(() => {
    setMaxHeight(isExpanded ? `${answerRef.current.scrollHeight}px` : '0px');
  }, [isExpanded]);

  const handleToggle = () => {
    onToggle();
  };

  const iconStyle = {
    transition: 'transform 0.3s ease-in-out, background 0.3s',
    transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
    cursor: 'pointer',
    background: isHovering || isExpanded ? 'black' : 'rgb(233, 233, 233)',
    color: isHovering || isExpanded ? 'white' : '',
    borderRadius: '50%',
    padding: '5px',
  };

  return (
    <SectionFAQ>
      <div className="faq-item">
        <div
          className="faq-question"
          onClick={handleToggle}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          <div className="DDD">{faq.question}</div>
          <div>
            <MdKeyboardArrowDown style={iconStyle} />
          </div>
        </div>
        <div
          className={`faq-answer-container ${isExpanded ? 'open' : ''}`}
          style={{ maxHeight: maxHeight, opacity: isExpanded ? '1' : '0' }}
        >
          <div ref={answerRef} className="faq-answer">
            {faq.answer}
          </div>
        </div>
      </div>
    </SectionFAQ>
  );
}

export default FaqItem;
