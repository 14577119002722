import styled, { keyframes } from 'styled-components';

const expandBackground = keyframes`
  0% {
    transform: translateX(0%);
  }
  40% {
    transform: translateX(-10%);
  }
  60% {
    transform: translateX(10%);
  }
  100% {
    transform: translateX(0);
  }
`;

export const HomePage = styled.section`
  height: auto;
  width: 100vw;
  background: #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  font-family: 'Concert One', sans-serif;

  .Header {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: "Nunito Sans", sans-serif;

    .headers {
      text-align: center;
      width: 90vw;
      height: 70vh;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      h2 {
        position: absolute;
        top: 25%;
        line-height: 1.8;
        letter-spacing: 0.03em;
        font-size: 45px;
        z-index: 1000;
        transition: background-color 0.3s, color 0.3s;
        background: #f4f4f4;
        border-radius: 30px;

        strong {
          background: none;
          padding: 10px;
        }

        &.intersected {
          background-color: green !important;
          color: red !important;
        }
      }

      p {
        position: absolute;
        bottom: 0%;
        font-size: 18px;
        line-height: 1.6;
        z-index: 10000;
      }

      .text-background {
        position: relative;
        z-index: 1;
        color: #388E3C;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: #A5D6A7;
          border-radius: 10px;
          z-index: -1;
          animation: ${expandBackground} 1s ease-in-out forwards;
          animation-delay: 1s;
        }
      }

      .text-color {
        color: gray;
        background: inherit;
        font-size: 13px;
        margin: 10px 0;
      }
    }
  }

  @media screen and (max-width: 800px) {
    .Header {
      height: 100vh;

      .headers {
        width: 87vw;
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        h2 {
          position: absolute;
          top: 22%;
          line-height: 1.8;
          letter-spacing: 0.03em;
          font-size: 35px;
          border-radius: 30px;
          padding: 40px 10px 40px 10px;
        }

        strong {
          background: none;
          padding: px;
          z-index: 1;
        }

        .text-background {
          font-family: "Bubblegum Sans", sans-serif;
          font-weight: 400;
          font-style: normal;
          font-size: 30px;

          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            animation: ${expandBackground} 1s ease-in-out forwards;
            animation-delay: 1s;
          }
        }

        .text-color {
          color: gray;
          background: inherit;
          font-size: 20px;
        }

        p {
          position: absolute;
          bottom: 0%;
          font-size: 18px;
          line-height: 1.6;
          padding: 10px;
          margin-bottom: 0px;
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    .Header {
      height: 95vh;

      .headers {
        width: 87vw;
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        h2 {
          position: absolute;
          top: 25%;
          line-height: 1.8;
          letter-spacing: 0.03em;
          font-size: 30px;
          border-radius: 30px;
        }

        strong {
          background: none;
          padding: px;
          z-index: 1;
        }

        .text-background {
          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            animation: ${expandBackground} 1s ease-in-out forwards;
            animation-delay: 1s;
          }
        }

        .text-color {
          color: gray;
          background: inherit;
          font-size: 13px;
          margin: 10px 0;
        }

        p {
          position: absolute;
          bottom: 2%;
          font-size: 18px;
          line-height: 1.6;
          padding: 10px;
          margin-bottom: 0px;
        }
      }

      button {
        position: absolute;
        bottom: 15%;
        padding: 25px;
        border-radius: 50px;
        margin: 60px 0 0;
        width: 70vw;
        color: white;
        font-size: 16px;
      }
    }
  }

  @media screen and (max-height: 720px) {
    .Header {
      .headers {
        width: 90vw;
        margin-top: 70px;
      }
    }
  }

  @media screen and (max-height: 820px) {
    .Header {
      height: 100vh;

      .headers {
        width: 97vw;
        margin-top: 70px;
      }
    }
  }

  @media screen and (max-height: 600px) {
    .Header {
      .headers {
        width: 90vw;
        margin-top: 70px;

        button {
          width: 80vw;
          padding: 20px;
        }

        p {
          bottom: 0%;
          padding: 0px;
        }
      }
    }
  }
`;

