// src/components/Home.js
import React, { useEffect, useState } from 'react';
import { HomePage } from "./StyledHome";
import Offers from './Offers';
import ProduictInfos from "../ProduictInfos/ProduictInfos";
import QuestionReponse from "../QuestionReponse/QuestionReponse";
import Marquee from "../Marquee/Marquee";
import Fidelity from "../FIdelity/Fidelity";
import ProductPackSelection from '../ProductPack/ProductPackSelection';
// import DivVerte from '../../utils/DivVerte';
import DivVerte2 from '../../utils/DivVerte2';

import styled from 'styled-components';
import { GiFlame } from "react-icons/gi";
import Liana from './Liana';

// Styled component for the TitleStyle
const TitleStyle = styled.h2`
  transition: opacity 0.2s ease-out;
  padding: 10px 0 0 0;
  font-family: "Bubblegum Sans", sans-serif;
  font-weight: 400;
  font-style: normal;

  .color-icons-flame {
    color: green;
    font-size: 30px;
    
  }
`;

const Home = () => {
  const [opacity, setOpacity] = useState(1);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const newOpacity = 1 - (scrollY / (50 * window.innerHeight / 100));
      setOpacity(Math.max(0, Math.min(1, newOpacity)));
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <HomePage>
      <Marquee />
      {/* <Liana /> */}
      <div className="Header">
        <div className="headers">
          {/* <DivVerte /> */}
          <DivVerte2 />
          <TitleStyle style={{ opacity: opacity }}>
            PuraMina,<strong className="text-background">votre allié</strong>
            <br />
            pour déverrouiller un dynamisme ancestral <GiFlame  className='color-icons-flame'/>
          </TitleStyle>
          <p>
            – <strong className="text-color">PuraMina Shilajit</strong>, un trésor de bien-être.
          </p>
        </div>
      </div>
      <div>
        <ProductPackSelection />
      </div>
      <Offers />
      <ProduictInfos />
      <QuestionReponse />
      <Fidelity />
    </HomePage>
  );
};

export default Home;
