import styled from 'styled-components';

export const NavGlobale = styled.nav`
  position: fixed;
  top: 6%; /* Ajustez en fonction de l'entête ou de la barre supérieure si nécessaire */
  left: 0;
  right: 0;
  margin: auto;
  padding: 10px;
  background: rgb(0, 0, 0);
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: space-around; /* Ajustement pour un meilleur espacement */
  height: auto; /* Hauteur fixe pour la cohérence */
  color: white;
  width: 90%;
  border-radius: 10px;
  z-index: 100000;
    transition: top 0.3s;

  &.initial {
    top: 6%;
  }

  &.visible {
    top: 0px;
  }

  &.hidden {
    top: -100px; /* Cache la nav quand on défile vers le bas */
  }

  &.payment-page {
    top: 0; /* Fixer la barre de navigation en haut pour la page de paiement */
  }

  .button-puramina {
    background: inherit;
    border: none;
  }

  .nav-left {
    flex-direction: row;
    gap: 20px;
  }

  img {
    object-fit: cover;
    display: block;
    border-radius: 10px;
    border: 1px solid white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin: 10px;
  }

  .image-responsive {
    width: 15vw;
    height: 100%;
  }

  @media (max-width: 1000px) {
    .image-responsive {
      width: 20vw;
      height: 100%;
    }
  }

  @media (max-width: 500px) {
    padding: 0px;
    .image-responsive {
      width: 30vw;
    }
  }


`;

export const NavItem = styled.button`
  background: inherit;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;

  .icon-nav {
    font-size: 30px;
  }

  @media (max-width: 1000px) {
    display: none;

    .nav-left {
      display: none;
    }
  }
`;

export const MenuIcon = styled.button`
  display: none;
  position: relative;

  @media (max-width: 1000px) {
    border-radius: 20%;
    border: 1px solid white;
    background-color: black;
    left: 10%;
    position: absolute;
    color: white;
    display: block;
    padding: 20px 20px;
    cursor: pointer;
    z-index: 11;

    .MenuIcon-1 {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:after {
      color: white;
      font-size: 24px;
    }
  }
  @media (max-width: 800px) {
    padding: 15px 15px;

  }
  @media (max-width: 500px) {
    font-size: 17px;
    padding: 11px 11px;
    display: flex;
    align-items: center;
    justify-content: center;

  }
  @media (max-width: 380px) {
    padding: 9px 9px;

  }
`;

export const ResponsiveMenu = styled.div`
  background: white;
  border-radius: 0 20px 20px 0;  // Bord arrondi uniquement sur les côtés droit haut et bas
  position: fixed;  // Position fixée pour rester en place lors du défilement
  left: 0;  // Aligné à gauche
  top: 0;  // Commence en haut de la page
  width: 30%;  // Prend la moitié de la largeur de la fenêtre
  height: 100%;  // S'étend sur toute la hauteur de la fenêtre
  padding: 80px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: ${({ $isExpanded }) => $isExpanded ? 'translateX(0)' : 'translateX(-100%)'};  // Animé de gauche à droite
  transition: transform 0.3s ease;  // Transition fluide pour l'animation

  button {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    font-size: 18px;
    background: gray;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  @media (max-width: 1000px) {
    width: 30%;  // Largeur accrue sur les petits écrans pour plus de visibilité
    border-radius: 0 20px 20px 0;  // Maintien des bords arrondis
  }
  @media (max-width: 700px) {
    width: 40%;  // Largeur accrue sur les petits écrans pour plus de visibilité
    border-radius: 0 15px 15px 0;  // Maintien des bords arrondis
  }
  @media (max-width: 500px) {
    width: 50%;  // Largeur accrue sur les petits écrans pour plus de visibilité
    border-radius: 0 15px 15px 0;  // Maintien des bords arrondis
  }
`;