// src/components/ProgressBar/ProgressBar.js
import React from 'react';
import {ProgressBarWrapper,Message,ProgressBarContainer,Progress,HighlightedText} from './StyledProgressBar'

const ProgressBar = ({ cartItems }) => {
  const calculateProgress = () => {
    const totalPrice = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
    if (totalPrice >= 59.98) {
      return 100;
    } else if (totalPrice >= 29.99) {
      return 50;
    } else {
      return 0;
    }
  };

  const progress = calculateProgress();

  const getMessage = () => {
    if (progress === 100) {
      return (
        <span>
          <HighlightedText>+ 1 Pot PURAMINA Offert 🎁</HighlightedText><br /> 
        </span>
      );
    } else if (progress === 50) {
      return "Ajoutez un Pot pour bénéficier de notre meilleure offre !";
    }
    return "";
  };

  const getMessageColor = () => {
    if (progress === 50) {
      return "#FB8C00"; // Orange for 50%
    }
    return "black";
  };

  return (
    <ProgressBarWrapper>
      {progress > 0 && (
        <Message color={getMessageColor()}>
          {getMessage()}
        </Message>
      )}
      <ProgressBarContainer>
        <Progress width={progress} />
      </ProgressBarContainer>
    </ProgressBarWrapper>
  );
};

export default ProgressBar;
