import React, { useEffect } from 'react';
import { PayementSuccess } from './StyledPayementPage';
import { GrValidate } from "react-icons/gr";
import sendFormDataToServer from '../../server/formService';

const Success = () => {
  useEffect(() => {
    // Récupérer les données du formulaire depuis localStorage
    const formData = JSON.parse(localStorage.getItem('formData'));
    if (formData) {
      // Envoyer les données du formulaire au serveur
      sendFormDataToServer(formData);
      // Nettoyer le localStorage après envoi
      localStorage.removeItem('formData');
    }
  }, []);

  return (
    <PayementSuccess>
      <div>
        <span><GrValidate className='icons-valider '/></span>
        <p className='A'>Paiement validé</p>
        <p>Merci de nous avoir fait confiance. Vous recevrez un email dans les heures à venir avec les détails de votre commande.</p>
        <p>Pour toute question, veuillez contacter notre service client à <a href="mailto:puramina.shilajit@gmail.com">support@puramina.com</a></p>
      </div>
    </PayementSuccess>
  );
};

export default Success;
