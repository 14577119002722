import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import ProductPackSelection from './components/ProductPack/ProductPackSelection';
import Home from './components/Home/Home';
import { ModalProvider } from './contexts/ModalContext';
import ComposentCommande from './components/Commande/ComposentCommande';
import NavBar from './components/nav/NavBar';
import CartModal from './components/modal/CartModal';
import Success from './components/Success/Success';
import Cancel from './components/Success/Cancel';
import LoadingPage from './components/LoadingPage/LoadingPage';
import Announcement from './components/LoadingPage/Announcement';
import PageSuivitCommande from './components/Footer/FooterComposents/PageSuivitCommande';
import PageContact from './components/Footer/FooterComposents/PageContact';
import PageCGUCGV from './components/Footer/FooterComposents/PageCGUCGV';
import useScrollToTop from '../src/hooks/useScrollToTop'
import PuraminaInfos from './components/PuraminaInfos/PuraminaInfos';

const AppContent = () => {
  const location = useLocation();
  const isPaymentPage = location.pathname === '/commande';

  useScrollToTop();

  return (
    <>
      <NavBar isPaymentPage={isPaymentPage} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/a" element={<ProductPackSelection />} />
        <Route path="/commande" element={<ComposentCommande />} />
        <Route path="/panier" element={<CartModal />} />
        <Route path="/success" element={<Success />} />
        <Route path="/cancel" element={<Cancel />} />
        <Route path="/suivit-de-ma-commande" element={<PageSuivitCommande />} />
        <Route path="/PageCGUCGV" element={<PageCGUCGV />} />
        <Route path="/PageContact" element={<PageContact />} />
        <Route path="/PuraminaPage" element={<PuraminaInfos/>} />

      </Routes>
    </>
  );
};

const App = () => {
  const [loading, setLoading] = useState(true);
  const [showAnnouncement, setShowAnnouncement] = useState(false);

  useEffect(() => {
    console.log('App mounted');
    setTimeout(() => {
      setLoading(false);
      console.log('loading finished');
      setShowAnnouncement(true);
    }, 1800); // Adjust the loading time if necessary
  }, []);

  console.log('Loading state:', loading);

  return (
    <Router>
      <ModalProvider>
        {loading ? (
          <LoadingPage />
        ) : (
          <>
            <AppContent />
            <Announcement show={showAnnouncement} />
          </>
        )}
      </ModalProvider>
    </Router>
  );
};

export default App;