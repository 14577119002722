import styled, { keyframes } from 'styled-components';

const fadeOut = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`;

const rotateAndGrow = keyframes`
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(1.1);
  }
  100% {
    transform: rotate(360deg) scale(1.5) translateY(10px);
  }
`;

export const LoaderWrapper = styled.div`
  font-family: 'Roboto', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: black;
  flex-direction: column;
  width: 100vw;
  padding: 0;
  margin: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  transition: opacity 1s ease-in-out;
`;

export const Loader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: ${({ $progress }) => ($progress >= 90 ? 1 - ($progress - 90) / 10 : 1)};
  animation: ${({ $progress }) => ($progress >= 90 ? fadeOut : 'none')} 1s forwards;


`;

export const LoadingText = styled.div`
font-family: "Bubblegum Sans", sans-serif;
  font-weight: 400;
  font-style: normal;  color: white;
  font-size: 24px;
  margin-bottom: 20px;
  transition: opacity 1s ease-in-out;
  opacity: ${({ $progress }) => ($progress >= 90 ? 1 - ($progress - 90) / 10 : 1)};
`;

export const ProgressBar = styled.div`
  width: ${({ $fullWidth }) => ($fullWidth ? '100vw' : '200%')};
  height: 2px;
  background: #444;
  border-radius: 2px;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
`;

export const Progress = styled.div`
  height: 100%;
  background: #fff;
  border-radius: 2px;
  position: absolute;
  left: 50%;
  transform-origin: left;
  transform: translateX(-50%);
  transition: width 0.2s ease-in-out;
`;

export const Percentage = styled.div`
  font-family: 'Trebuchet MS', sans-serif;
  color: white;
  font-size: 18px;
  transition: opacity 1s ease-in-out;
  opacity: ${({ $progress }) => ($progress >= 90 ? 1 - ($progress - 90) / 10 : 1)};
`;

export const LogoImage = styled.img`
  object-fit: cover;
  display: block;
  border-radius: 50px;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 10%;
  height: 5%;
  animation: ${rotateAndGrow} 2s linear infinite;
`;
