// src/components/ProduictInfos.js
import React from 'react';
import { Infos } from './StyledProduictInfos';
import ShilajitPur from '../../assets/image/ShilajitPur.webp';
import MeanMonsterShilajit from '../../assets/image/MeanMonsterShilajit.webp';
import SwipeHighlightText from '../../utils/SwipeHighlightText';

const ProduictInfos = () => {
  return (
    <Infos>
      <div className='Infos-1'>
        <div className='Shilajit-image'>
          <img src={MeanMonsterShilajit} alt='Shilajit Marques' className='image-responsives' />
        </div>
        <div className='Shilajit-infos'>
          <h3>Venu des sommets de l'Himalaya.</h3>
          <SwipeHighlightText>
            <p className='Titre'>QU'EST-CE QUE LE SHILAJIT ?</p>
          </SwipeHighlightText>
          <SwipeHighlightText>
            <p>
            Le Shilajit : Une résine naturelle aux propriétés nutritives exceptionnelles et riche en minéraux essentiels.
            </p>
          </SwipeHighlightText>
          <SwipeHighlightText>
            <p>
            Notre Shilajit Puramina est soigneusement récolté dans les altitudes pures de l'Himalaya à plus de 18 000 pieds, reconnu pour dynamiser le corps et l'esprit, apportant une énergie naturelle et soutenant un équilibre harmonieux.
            </p>

          </SwipeHighlightText>

          <SwipeHighlightText>
            <p>
            Consommé depuis plus de 4 000 ans par les populations asiatiques, le Shilajit est révéré pour sa capacité à revitaliser le corps et l'esprit, favorisant une énergie naturelle optimale.
            </p>
          </SwipeHighlightText>
        </div>
      </div>

      <div className='Infos-2'>
        <div className='Shilajit-image'>
          <img src={ShilajitPur} alt='Shilajit Marques' className='image-responsives' />
        </div>
        <div className='Shilajit-infos-2'>
          <h3>Nos produits sont approuvés 100 % PURE</h3>
          <SwipeHighlightText>
            <p className='Titre'>LIBÈRE TA PUISSANCE OPTIMALE.</p>
          </SwipeHighlightText>
          <SwipeHighlightText>
            <p>
            Plongez dans une source inépuisable de vitalité avec notre formule exclusive de Shilajit Puramina, 
            enrichie de plus de 85 oligo-éléments, vitamines et macro-nutriments essentiels.
            </p>
          </SwipeHighlightText>
          <SwipeHighlightText>
            <p>
            Conçu pour fournir un soutien nutritionnel complet, notre Shilajit favorise un bien-être holistique et 
            une performance physique accrue pour les hommes et les femmes en quête d'équilibre et de vitalité.
            </p>
          </SwipeHighlightText>

        </div>
      </div>

      <div className='Infos-3'>
        <div className='Shilajit-image'>
        <img src={ShilajitPur} alt='Shilajit Marques' className='image-responsives' />

        </div>
        <div className='Shilajit-infos-3'>
          <h3>100% PURE</h3>
          <SwipeHighlightText>
            <p className='Titre'>Comment prendre le Shilajit Puramina ?</p>
          </SwipeHighlightText>
          <SwipeHighlightText>
            <p>
            Pour profiter des vertus du Shilajit Puramina, prenez l'équivalent d'un grain de riz, 
            une fois par jour. Cette dose est idéale pour augmenter votre vitalité.
            </p>
          </SwipeHighlightText>
          <SwipeHighlightText>
            <p>
            Intégrez facilement le Shilajit à votre routine en le mélangeant à votre boisson favorite : café, thé ou eau. Le Shilajit 
            Puramina se dissout parfaitement, vous aidant à commencer la journée avec dynamisme et sérénité.
            </p>
          </SwipeHighlightText>
          <SwipeHighlightText>
            <p>
            Conseil : pour une meilleure absorption, consommez le Shilajit dans une boisson chaude.
            </p>
          </SwipeHighlightText>
        </div>
      </div>
    </Infos>
  );
};

export default ProduictInfos;
