// src/components/SwipeHighlightText.js
// pour gérer le surlignage des éléments de texte pendant que l'utilisateur effectue un geste de balayage.
import React, { useState, useEffect } from 'react';
import { handleSwipeHighlight } from '../utils/swipeHighlight';

const SwipeHighlightText = ({ children }) => {
  const [highlightedLine, setHighlightedLine] = useState(null);

  useEffect(() => {
    const handleTouchMove = (event) => handleSwipeHighlight(event, setHighlightedLine);

    document.addEventListener('touchmove', handleTouchMove);

    return () => {
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);

  useEffect(() => {
    // Remove background from all lines
    const allParagraphs = document.querySelectorAll('.highlightable p');
    allParagraphs.forEach(p => p.style.backgroundColor = 'transparent');

    // Add background to the highlighted line
    if (highlightedLine) {
      highlightedLine.style.backgroundColor = '#F1F8E9';
    }
  }, [highlightedLine]);

  return (
    <div className="highlightable">
      {React.Children.map(children, child => 
        React.cloneElement(child, { style: { ...child.props.style, padding: '10px', borderRadius: '15px' } })
      )}
    </div>
  );
};

export default SwipeHighlightText;
