import styled from 'styled-components';

export const MarqueeOffre = styled.section`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  box-shadow: 0px 2px 5px rgba(0,0,0,0.2);
  background: black;
  position: absolute;
  top: 0%;
  font-size: 13px;

  p {
    display: inline-block;
    animation: marquee 300s linear infinite;
    margin: 0;
    white-space: nowrap;
    color: white;
    padding: 15px;
    height: 1.3vh;
  }

  @keyframes marquee {
    from { transform: translateX(0); }
    to { transform: translateX(calc(-80%)); }
  }

  @media (max-width: 400px) {
    p {
      font-size: 12px;
      height: 1.3vh;
    }
  }



  @media (max-height: 820px) {
    p {
      font-size: 11px;
      height: 1vh;
    }
  }
`;
