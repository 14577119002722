import React, { useEffect } from 'react';
import styled from 'styled-components';
import useFloatingLabel from '../../hooks/input/useFloatingLabel';

const FloatingLabelContainer = styled.div`
  position: relative;
  margin: 0px;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 20px;
  font-size: 15px;
  border: 1px solid ${({ $isFocused, $isFilled }) => ($isFocused || $isFilled ? 'green' : '#ccc')};
  border-radius: 4px;
  outline: none;
  
  &:focus {
    border-color: black;
  }
`;

const StyledLabel = styled.label`
  position: absolute;
  top: ${({ $isFocused, $isFilled }) => ($isFocused || $isFilled ? '5px' : '60%')};
  left: 10px;
  font-size: ${({ $isFocused, $isFilled }) => ($isFocused || $isFilled ? '12px' : '14px')};
  color: ${({ $isFocused, $isFilled }) => ($isFocused || $isFilled ? 'black' : '#aaa')};
  transition: all 0.2s ease-out;
  transform: translateY(${({ $isFocused, $isFilled }) => ($isFocused || $isFilled ? '0' : '-50%')});
  pointer-events: none;
  background: white;
  padding: 0 5px;
`;

const FloatingLabelInput = ({ type, label, value, onChange, ...props }) => {
  const { isFocused, setIsFocused, isFilled, setValue } = useFloatingLabel(value);

  useEffect(() => {
    setValue(value);
  }, [value, setValue]);

  return (
    <FloatingLabelContainer>
      <StyledInput
        type={type}
        value={value}
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        $isFocused={isFocused}
        $isFilled={isFilled}
        {...props}
      />
      <StyledLabel $isFocused={isFocused} $isFilled={isFilled}>
        {label}
      </StyledLabel>
    </FloatingLabelContainer>
  );
};

export default FloatingLabelInput;
