// src/components/modal/CartModal.js
import React, { useState, useEffect } from 'react';
import { IoClose } from "react-icons/io5";
import { IoMdCloseCircle } from "react-icons/io";
import useCartActions from '../../hooks/PanierGestion';
import ModalContainer from "./ModalContainer";
import ProductPackOption from '../ProductPack/ProductPackOption';
import packs from '../../data/Packs'; // Importer les packs
import FinalCommande from './FinalCommande';
import Backdrop from './ModalBackdrop';
import { ModalComble } from './StyledCartModal'; // Importer les styles
import ProgressBar from '../ProgressBar/ProgressBar'; // Importer ProgressBar
import { CgTrash } from "react-icons/cg";
import TitleModal from './TitleModal'

const CartModal = ({ onClose }) => {
  const { cart, incrementQuantity, decrementQuantity, handleQuantityChange, handleRemoveItem, addToCart } = useCartActions();
  const [isHovered, setIsHovered] = useState(false);
  const [selectedPack, setSelectedPack] = useState(null); // Ajouter un état pour le pack sélectionné
  const [showNotification, setShowNotification] = useState(false);

  const handleClickOutside = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  const handleSelectPack = (pack) => {
    const testPack = packs.find(p => p.id === 1);
    const premiumPack = packs.find(p => p.id === 2);
    const testPackCount = cart.items.reduce((count, item) => item.id === testPack.id ? count + item.quantity : count, 0);

    if (testPackCount >= 1 && pack.id === 1) {
      // Remove all test packs
      cart.items.filter(item => item.id === testPack.id).forEach(item => handleRemoveItem(item.id));

      // Add the premium pack
      const existingPremiumPack = cart.items.find(item => item.id === premiumPack.id);

      if (existingPremiumPack) {
        // Increment the quantity of the existing premium pack
        incrementQuantity(existingPremiumPack.id);
      } else {
        // Add the premium pack
        addToCart(premiumPack);
      }
      setSelectedPack(premiumPack);
      setShowNotification(true); // Show notification
      setTimeout(() => setShowNotification(false), 3000); // Hide after 3 seconds
    } else {
      addToCart(pack);
      setSelectedPack(pack);
    }
  };

  const handleRemoveItemAndCheckEmpty = (id) => {
    handleRemoveItem(id);
    if (cart.items.length === 1) {
      setSelectedPack(null);
    }
  };

  useEffect(() => {
    const testPack = packs.find(p => p.id === 1);
    const premiumPack = packs.find(p => p.id === 2);
    const testPackCount = cart.items.reduce((count, item) => item.id === testPack.id ? count + item.quantity : count, 0);

    if (testPackCount >= 2) {
      // Remove all test packs
      cart.items.filter(item => item.id === testPack.id).forEach(item => handleRemoveItem(item.id));

      // Add the premium pack
      const existingPremiumPack = cart.items.find(item => item.id === premiumPack.id);

      if (existingPremiumPack) {
        // Increment the quantity of the existing premium pack
        incrementQuantity(existingPremiumPack.id);
      } else {
        // Add the premium pack
        addToCart(premiumPack);
      }
      setSelectedPack(premiumPack);
      setShowNotification(true); // Show notification
      setTimeout(() => setShowNotification(false), 3000); // Hide after 3 seconds
    }
  }, [cart.items, addToCart, incrementQuantity, handleRemoveItem]);

  return (
    <Backdrop onClick={handleClickOutside}>
      <ModalContainer>
        <ModalComble>
          <TitleModal /> 

          {showNotification && (
            <div className='notification'>
              Votre pack a été mis à jour au Pack Premium !
            </div>
          )}

          {cart.items.length === 0 ? ( // lorsque le panier est vide
            <div className='commande-visible-off'>
              <p>Votre panier est vide</p>
              <p>choisit un pack</p>
              {selectedPack ? (
                <div key={selectedPack.id} className='selected-pack'>
                  <span> - {selectedPack.price}€</span>
                </div>
              ) : (
                packs.map((pack) => (
                  <ProductPackOption
                    key={pack.id}
                    pack={pack}
                    onSelect={() => handleSelectPack(pack)}
                    isBestOffer={pack.id === 2}
                    isSelected={false}
                    title={pack.id === 1 ? "Pack Teste" : "Pack Premium"} // Ajouter le titre ici
                  />
                ))
              )}
            </div>
          ) : (
            <>
              <ProgressBar cartItems={cart.items} />
              {cart.items.map(item => ( // lorsque le panier est rempli
                <div key={item.id} className='selected-pack'>
                  <span>
                    <input
                      type="number"
                      value={item.quantity}
                      onChange={(e) => handleQuantityChange(item.id, e)}
                      min="1"
                    />
                    {item.title} <span className="button-title2">{item.id === 1 ? "Pack Teste" : "Pack Premium"}</span>
                  </span>
                  <div className='quantity-pots'>
                    <button onClick={() => incrementQuantity(item.id)}>+</button>
                    <button onClick={() => decrementQuantity(item.id)}>-</button>
                    <button onClick={() => handleRemoveItemAndCheckEmpty(item.id)}><CgTrash /></button>
                  </div>
                  <span>{(item.price * item.quantity).toFixed(2)}€</span>
                </div>
              ))}
            </>
          )}
          <button
            onClick={onClose}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className='button-close-sup'
          >
            {isHovered ? <IoMdCloseCircle className='button-close' /> : < IoClose className='button-close' />}
          </button>

          {(selectedPack || cart.items.length > 0) && <FinalCommande />}
        </ModalComble>
      </ModalContainer>
    </Backdrop>
  );
};

export default CartModal;
