import styled from 'styled-components';

export const GlobalComposent = styled.section`
background: rgb(255, 255, 255);
height: 100vh;
width: 100vw;
display: flex;
align-items: center;

@media (max-height: 750px) {
  align-items: flex-end;

}
`


export const CommandeContainer = styled.div`
  width: 50vw;
  margin: 30px auto;
  padding: 60px 20px 20px 20px;
  font-family: Arial, sans-serif;
  background-color: rgb(251, 250, 250);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: auto;
  overflow-x: hidden;


  h1, h2, h3 {
    text-align: center;
  }

  h2 {
    margin-top: 20px;
    font-size: 18px;
  }

  input, select, button {
    width: 100%;
    padding: 10px;
    margin: 10px 0 1px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }

  .top-commande {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  @media (max-width: 1200px) {
    width: 60vw;
  }

  @media (max-width: 800px) {
    width: 75vw;
    font-size: 11px;
  }

  @media (max-width: 500px) {
    width: 95vw;
    padding: 60px 5px 20px 5px;

    .top-commande {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  @media (max-width: 321px) {
    padding: 20px 10px;

    .top-commande {
      flex-direction: column;
      align-items: flex-start;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  button {
    width: 48%;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .shop-pay {
    background-color: #5a2ff1;
    color: white;
  }

  .apple-pay {
    background-color: black;
    color: white;
  }

  @media (max-width: 321px) {
    flex-direction: column;

    button {
      width: 100%;
      margin-bottom: 10px;
    }
  }
`;

export const Divider = styled.div`
  text-align: center;
  margin: 20px 0;
  font-size: 14px;
  color: #888;
  position: relative;

  &::before, &::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 45%;
    height: 1px;
    background: #ccc;
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }

  @media (max-width: 321px) {
    &::before, &::after {
      width: 40%;
    }
  }
`;


export const Section2 = styled.div`
  h2 {
    margin-bottom: 10px;
  }

  @media (max-width: 321px) {
    h2 {
      font-size: 16px;
    }
  }
`;


export const Section4 = styled.div`
  margin-top: 20px;

  h2 {
    margin-bottom: 10px;
  }

  p {
    color: #9e9e9e;
  }

  img {
    display: flex;
    justify-content: flex-end;
    width: 20vw;
    border-radius: 11px;
    border: none;
  }

  @media (max-width: 500px) {
    img {
      width: 35vw;
      border-radius: 11px;
      border: none;
    }

    .image-responsiveee {
      border: 4px solid white;
    }

    p {
      font-size: 14px;
    }
  }

  @media (max-width: 321px) {
    h2 {
      font-size: 16px;
    }

    img {
      width: 30vw;
      border-radius: 11px;
      border: none;
      margin-top: 10px;
    }
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 5px 5px 10px;
  border: none;
  border-radius: 5px;

  input {
    width: auto;
    margin-right: 10px;
    flex-shrink: 0;
    border: none;
  }

  label {
    border: none;
    flex: 1;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: 500px) {
    input {
      margin-right: 5px;
    }

    label {
      font-size: 13px;
    }
  }

  @media (max-width: 370px) {
    label {
      font-size: 12px;
    }
  }
`;

export const NameFields = styled.div`
`;

export const LocationFields = styled.div`
`;

export const SummaryToggle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  background: #f1f1f1;
  border-radius: 5px;
  margin-bottom: 20px;

  span {
    font-weight: 700;
  }

  @media (max-width: 500px) {
    span {
      font-size: 12px;
    }
  }

  @media (max-width: 321px) {
    padding: 5px;

    span {
      margin-bottom: 5px;
    }
  }
`;

export const OrderSummary = styled.div`
  background: #f9f9f9;
  padding: 10px;
  border: 1.5px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
  height: auto;
  flex-direction: column;

  .top-sommaire {
    height: 9vh;
  }

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 50px;
      height: 50px;
      object-fit: cover;
      margin-right: 10px;
    }

    p {
      margin: 0;
    }

    span {
      font-weight: bold;
    }
  }

  @media (max-width: 500px) {
    span {
      font-size: 13px;
    }
  }

  @media (max-width: 321px) {
    padding: 5px;

    div {
      flex-direction: column;
      align-items: flex-start;

      img {
        margin-bottom: 10px;
      }
    }
  }
`;

export const ExpressPaymentContainer = styled.div`
  text-align: center;

  p {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  @media (max-width: 321px) {
    p {
      font-size: 16px;
    }
  }
`;

export const PaymentSection = styled.div`
  .credit-card {
    object-fit: cover;
    display: block;
    border-radius: 10px;
    border: 1px solid white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 30vw;
    margin: 10px;
    
    .image-payment {
      width: 20vw;
      height: 100%;
    }
  }

  h3 {
    margin: 0;
    font-size: 18px;
  }

  div {
    display: flex;
    gap: 10px;
  }

  .card-details {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    input {
      width: 100%;
    }
  }

  @media (max-width: 500px) {
    margin: 0px;
  }

  @media (max-width: 321px) {
    .credit-card {
      flex-direction: column;
      align-items: flex-start;

      .image-payment {
        margin-top: 10px;
      }
    }

    .card-details {
      flex-direction: column;
      gap: 5px;
    }
  }
`;
