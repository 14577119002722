// src/components/Footer/FooterComponents/PageCGUCGV.js
import React from 'react';
import styled from 'styled-components';
import Fidelity from '../../FIdelity/Fidelity';
import Marquee from '../../Marquee/Marquee';

const TopCGV = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  flex-direction: column;
  padding: 20px;
  position: relative;
  .top {
    margin: 60px 0 10px;
    height: 20vh;
    position: absolute;
    top: 20%;
    width: 90%;
  }
  .down {
    position: absolute;
    margin: 0 0 15px;
    height: auto;
    width: 90%;
    top: 35%;
    p {
        margin: 0 0 30px;
    }
  }
  @media (max-width: 500px) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex-direction: column;
    padding: 20px;
    position: relative;
    .top {
      height: 20vh;
      position: absolute;
      top: 13%;
      width: 90%;
      h1 {
        font-size: 25px;
      }
    }
    .down {
      position: absolute;
      margin: 0 0 15px;
      height: auto;
      width: 90%;
      top: 30%;
      p {
          margin: 0 0 20px;
      }
    }
  }
`;

const PageCGUCGV = () => {
  return (
    <div>
        <Marquee />
      <TopCGV>
        <div className='top'>
        <h1>CONDITIONS GÉNÉRALES DE VENTE</h1>
        </div>
        <div className='down'>
        <p>1. Objet Les présentes conditions générales de vente définissent les droits et obligations des parties dans le cadre de la vente de nos produits de résine de shilajit.</p>
        <p>2. Commandes Les commandes sont passées via notre site web. En passant commande, le client reconnaît avoir pris connaissance et accepté nos conditions générales de vente.</p>
        <p>3. Prix Les prix de nos produits sont indiqués en euros toutes taxes comprises. Les frais de livraison sont à la charge du client et sont indiqués lors du processus de commande.</p>
        <p>4. Paiement Le règlement s'effectue en ligne au moment de la commande. Nous acceptons les paiements par carte bancaire ou tout autre moyen de paiement sécurisé proposé sur notre site.</p>
        <p>5. Livraison Nous nous engageons à livrer les produits dans les meilleurs délais après réception du paiement. Les délais de livraison sont indiqués lors du processus de commande et peuvent varier en fonction de la destination.</p>
        <p>6. Droit de rétractation Le client dispose d'un délai de 14 jours à compter de la réception des produits pour exercer son droit de rétractation. Les frais de retour sont à la charge du client.</p>
        <p>7. Litiges En cas de litige, les parties s'engagent à rechercher une solution amiable. À défaut, les tribunaux compétents seront ceux du lieu de notre siège social.</p>

        </div>
      </TopCGV>
      <div>
        <Fidelity />
      </div>
    </div>
  );
};

export default PageCGUCGV;
